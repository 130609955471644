import i18next from 'i18next';

import { ROLES } from '#constants/users';

i18next.addResources('es', 'WorkspaceModal', {
  cancel: 'Cancelar',
  delete: 'Eliminar',
  save: 'Guardar',
  abandon: 'Abandonar',
  inviteMembers: 'INVITAR USUARIOS',
  sendInvitations: 'Enviar invitaciones',
  emailRequiredError: 'Este campo es requerido',
  alreadyMemberError: 'El usuario ingresado ya pertenece al espacio de trabajo',
  hasPendingInvitationError: 'El usuario ingresado ya tiene una invitación pendiente',
  selfInvitationError: 'No puede invitarse a usted mismo',
  deleteModalTitle: '¿ELIMINAR USUARIO DE ESPACIO DE TRABAJO?',
  deleteInvitationModalTitle: '¿ELIMINAR INVITACIÓN?',
  deleteMember: 'Usted va a eliminar a:',
  deleteInvitation: 'Usted va a eliminar la invitación a:',
  deleteModalDescription:
    'El usuario perderá acceso a todos los expedientes y tareas de este espacio. Deberá crear o ser invitado a un nuevo espacio de trabajo para poder continuar utilizando Ubuntulaw.',
  deleteInvitationDescription: 'La invitación dejará de figurar en su lista de miembros',
  editModalTitle: 'CAMBIAR EL ROL DE USUARIO',
  editModalDescription: 'Usted cambiará el rol de:',
  editModalWarning:
    'Está por transferir su rol de Dueño a {{ user }}, si lo hace se cancelará la suscripción de este espacio, y el nuevo dueño deberá volver a suscribirse.',
  successInvitationsToast: 'Invitaciones enviadas exitosamente',
  successDeleteMemberToast: 'El usuario se ha eliminado exitosamente',
  successDeleteInvitationToast: 'Invitación eliminada exitosamente',
  successResendToast: 'Invitación reenviada exitosamente',
  [`successEditMemberToast${ROLES.ADMIN}`]: 'El rol ha sido cambiado exitosamente',
  [`successEditMemberToast${ROLES.LAWYER}`]: 'El rol ha sido cambiado exitosamente',
  [`successEditMemberToast${ROLES.OWNER}`]: 'Su rol de Dueño fue transferido con éxito',
  descriptionEditMemberToast: 'Su nuevo rol es Administrador',
  [`abandonModalTitle${ROLES.OWNER}`]: 'NO PUEDE ABANDONAR ESTE ESPACIO DE TRABAJO',
  [`abandonModalTitle${ROLES.ADMIN}`]: '¿ABANDONAR ESPACIO DE TRABAJO?',
  [`abandonModalTitle${ROLES.LAWYER}`]: '¿ABANDONAR ESPACIO DE TRABAJO?',
  abandonModalDescOwner:
    'Para poder abandonar el espacio de trabajo, primero debe transferir el rol de Dueño a otro miembro de este espacio.',
  abandonModalDescLawyer:
    'Perderá acceso a todos los expedientes y tareas de este espacio. Deberá crear o ser invitado a un nuevo espacio de trabajo para poder continuar utilizando Ubuntulaw.'
});
