import i18next from 'i18next';

i18next.addResources('es', 'ElectronicCards', {
  title: 'CÉDULAS ELECTRÓNICAS',
  notificationDate: 'Fecha de notificación',
  credentials: 'Credencial',
  fileNumber: 'N° expediente',
  cover: 'Carátula',
  searchBar: 'Buscar por N° expediente o carátula',
  filterTitle: 'Por fecha de notificación',
  showOnlyAssigned: 'Solo mis asignados'
});
