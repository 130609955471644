import i18next from 'i18next';

i18next.addResources('es', 'ScheduleReport', {
  title: 'DESCARGAR AGENDA',
  date: 'Fecha',
  reportRange: 'Rango de informe',
  casesFilter: 'Filtrar por expedientes (opcional)',
  selectAll: 'Todos los expedientes',
  searchBar: 'Carátula',
  singleLabel: 'Fecha',
  rangeLabel: 'Rango de fechas',
  monthLabel: 'Mes',
  singleError: 'No se encontraron eventos en la fecha seleccionada',
  rangeError: 'No se encontraron eventos en el rango seleccionado',
  monthError: 'No se encontraron eventos en el mes seleccionado',
  noCaseSelected: 'No seleccionó ningún expediente',
  agendaReport: 'Informe de Agenda'
});
