export const API_ERRORS = {
  CLIENT_ERROR: 'CLIENT_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  CANCEL_ERROR: 'CANCEL_ERROR'
};

export const REDIRECT_API_ERRORS = [
  API_ERRORS.SERVER_ERROR,
  API_ERRORS.TIMEOUT_ERROR,
  API_ERRORS.CONNECTION_ERROR,
  API_ERRORS.NETWORK_ERROR
];
