import { bool } from 'prop-types';
import { useSelector } from 'react-redux';

import { useLogout } from '#hooks/useLogout';

import styles from './styles.module.scss';
import MenuItem from './components/MenuItem';
import { useSideBar } from './hooks';
import MenuHeader from './components/MenuHeader';
import { bottomMenuItems, topMenuItems } from './utils';
import WorkspaceItem from './components/WorkspaceItem';

function SideBar({ canBeHided }) {
  const { ref, isOpen, Container, props } = useSideBar({ canBeHided });
  const currentUser = useSelector(state => state.auth.currentUser);
  const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
  const handleLogout = useLogout();

  return (
    <Container ref={ref} {...props}>
      <MenuHeader />
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {topMenuItems(currentUser, currentWorkspace).map(item => (
            <MenuItem key={item.name} item={item} isOpen={isOpen} onClick={item?.onClickIcon} />
          ))}
        </div>
        <div className="w-full">
          <div className="mt-2 flex w-full flex-col justify-center">
            <WorkspaceItem isOpen={isOpen} />
            <div className="my-2 h-px w-full rounded bg-neutral-300" />
            {bottomMenuItems(currentUser, handleLogout).map(({ item, ...menuProps }) => (
              <MenuItem key={item.name} item={item} isOpen={isOpen} {...menuProps} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}

SideBar.propTypes = {
  canBeHided: bool
};

export default SideBar;
