import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { TARGETS } from './constants';

const initialState = {
  [TARGETS.SETTINGS]: {},
  [TARGETS.SERVER_ERROR]: false,
  [TARGETS.SCRAPER_HEALTH]: { isHealthy: true }
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [actions.GET_SETTINGS, actions.GET_SCRAPER_HEALTH],
  override: {
    [actions.SET_SERVER_ERROR]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
