import { ORDER } from '#constants/order';
import { MONGO_KEYS } from '#constants/query';

export const getOrder = order => (order === ORDER.ASC ? ORDER.DESC : ORDER.ASC);

const regexTrasnform = value => `.*${value}.*`;

export const buildSearchQuery = (fields = [], value) => ({
  or: fields?.map(({ field }) => ({ [field]: { regex: regexTrasnform(value) } }))
});

export const buildFilterQuery = filterValues =>
  Object.entries(filterValues).reduce((acc, [key, values]) => {
    acc[key] = { in: Object.values(values).flat() };
    return acc;
  }, {});

export const queryBuilder = ({
  collection,
  searchQuery,
  filterQuery = [],
  sortQuery,
  ...additionalFilters
}) => ({
  [collection]: {
    sort: { ...sortQuery },
    filter: {
      [MONGO_KEYS.AND]: [searchQuery, ...filterQuery, additionalFilters]
    }
  }
});
