import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onSpreadValue, onReadValue } from 'redux-recompose';

import { updateCaseOverride, updateSelectedCaseOverride } from '#models/cases';

import { actions } from './actions';
import { TARGETS } from './constants';

const defaultState = completeState({
  selectedCases: { result: [] },
  importCases: null,
  importCase: null,
  currentCase: null,
  updateLegalCase: null,
  importActions: [],
  importExperts: [],
  importParticipants: [],
  importProsecutors: [],
  importInvolvedFiles: [],
  importExpertEvidence: [],
  importTestimonialEvidence: [],
  importDocumentaryEvidence: [],
  importTestimonyEvidence: [],
  importOtherEvidence: [],
  uploadEvidence: null,
  updateEvidence: null,
  evidenceData: null,
  evidenceByPart: null,
  deleteEvidence: null,
  importNotes: [],
  searchCase: null,
  addCase: null,
  leaveNote: null,
  assigneesError: null
});

const reducerDescription = {
  primaryActions: [
    actions.GET_CASES,
    actions.GET_IMPORTED_CASES,
    actions.IMPORT_CASES,
    actions.IMPORT_CASE,
    actions.GET_CASE_BY_ID,
    actions.IMPORT_LEGAL_CASE_LIST_FIELD,
    actions.IMPORT_INVOLVED_FILES,
    actions.UPDATE_LEGAL_CASE,
    actions.IMPORT_EVIDENCE,
    actions.UPLOAD_EVIDENCE,
    actions.UPDATE_EVIDENCE,
    actions.DELETE_EVIDENCE,
    actions.SEARCH_PUBLIC_CASE,
    actions.ADD_PUBLIC_CASE,
    actions.LEAVE_NOTE
  ],
  override: {
    [actions.RESET_IMPORT_CASE_STATE]: onSpreadValue(),
    [actions.SET_EVIDENCE_DATA]: onReadValue(),
    [actions.UPDATE_SELECTED_CASE]: updateSelectedCaseOverride(),
    [actions.UPDATE_INVOLVED_FILE]: updateCaseOverride(TARGETS.IMPORT_INVOLVED_FILES),
    [actions.SET_ASSIGNEES_ERROR]: onReadValue()
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
