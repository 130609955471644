import i18next from 'i18next';

i18next.addResources('es', 'PJNLoginForm', {
  password: 'Contraseña',
  enterPassword: 'Ingrese su contraseña',
  cuit: 'CUIT/CUIL*',
  enterCUIT: 'Ingrese su CUIT/CUIL',
  invalidCuit: 'El CUIT/CUIL ingresado no es válido',
  unauthorizedError: 'La contraseña o CUIT/CUIL no son correctos.',
  connect: 'Conectar',
  loadingMessage: 'Conectandose al sistema',
  forgotPassword: '¿Olvidaste tu contraseña?',
  privacyPolicy: 'Politicas de privacidad',
  required: 'Este campo es obligatorio',
  withoutSpaces: 'Sin guiones ni espacios',
  later: 'Más tarde',
  connectYourPJNCredential:
    'Conecte su credencial del <strong>Poder Judicial de la Nación</strong> para comenzar a seguir todos sus casos.'
});

i18next.addResources('en', 'PJNLoginForm', {
  password: 'Password',
  enterPassword: 'Enter your password',
  cuit: 'CUIT/CUIL*',
  enterCUIT: 'Enter your CUIT/CUIL',
  invalidCuit: 'The entered CUIT/CUIL is invalid',
  unauthorizedError: 'Invalid CUIT/CUIL or password',
  connect: 'Connect',
  loadingMessage: 'Connecting to the system',
  forgotPassword: 'Forgot password?',
  privacyPolicy: 'Privacy policy',
  required: 'Required',
  withoutSpaces: 'Without dashes and spaces',
  later: 'Later',
  connectYourPJNCredential:
    'Connect your <strong>Poder Judicial de la Nación</strong> credential to start following all your cases.'
});

i18next.addResources('es', 'PJNLoginError', {
  message: 'No se pudo conectar el sistema. Intentelo nuevamente',
  connect: 'Conectar'
});

i18next.addResources('es', 'PJNLoginSuccess', {
  message: 'El sistema se conectó con éxito',
  continue: 'Continuar'
});

i18next.addResources('es', 'PJNLoginLoading', {
  message: 'Estamos conectando el sistema'
});
