import { onReadValue } from 'redux-recompose';

import { TARGETS } from '#redux/LegalCases/constants';

export const shouldShowImportComponent = ({ component, importState, isImported, isPublic }) =>
  (component.importState === importState || (component.showWhenImported && isImported)) &&
  !(component.hideIfPublic && isPublic);

export const updateCaseOverride = target =>
  onReadValue(({ payload: { id, ...values } }, state) =>
    state[target].map(caseToUpdate =>
      caseToUpdate.id === id ? { ...caseToUpdate, ...values } : caseToUpdate
    )
  );

export const updateSelectedCaseOverride = () =>
  onReadValue(({ payload: { id, ...values } }, state) => {
    const selectedCases = state[TARGETS.SELECTED_CASES];
    return {
      ...selectedCases,
      result: selectedCases.result.map(caseToUpdate =>
        caseToUpdate.id === id ? { ...caseToUpdate, ...values } : caseToUpdate
      )
    };
  });

export const getUniqueCases = legalCases =>
  legalCases.filter((legalCase, index, self) => self.findIndex(value => value.id === legalCase.id) === index);
