import i18next from 'i18next';

i18next.addResources('es', 'EvidenceUploadSteps', {
  evidenceType: 'Tipo de prueba',
  generalData: 'Datos generales',
  particularData: 'Datos particulares',
  expertReport: 'Informe pericial',
  expertiseObjection: 'Impugnación de pericia',
  addAttachment: 'Agregar adjunto',
  addDigitalDocument: 'Documento digital',
  audienceReport: 'Acta de audiencia',
  objection: 'Impugnación',
  reply: 'Contestación'
});

i18next.addResources('es', 'EvidenceUploadButtons', {
  cancel: 'Cancelar',
  previous: 'Anterior',
  next: 'Siguiente',
  finish: 'Finalizar',
  addWitnesses: 'Agregar testigos'
});

i18next.addResources('es', 'EvidenceUploadTitles', {
  addEvidence: 'Agregar prueba',
  informative: 'Prueba informativa',
  expert: 'Prueba pericial',
  documentary: 'Prueba documental',
  testimony: 'Prueba testimonial',
  other: 'Otras pruebas'
});

i18next.addResources('es', 'EvidenceUploadSubtitle', {
  selectEvidenceType: 'Seleccione el tipo de prueba',
  generalData: 'Datos generales',
  expertEvidenceData: 'Datos de prueba pericial',
  expertData: 'Datos del perito',
  uploadExpertEvidence: 'Cargar informe pericial',
  uploadExpertiseObjection: 'Cargar impugnación de pericia',
  informativeEvidenceData: 'Datos de prueba informativa',
  uploadObjection: 'Cargar impugnación de respuesta',
  uploadResponse: 'Cargar contestación',
  documentaryEvidenceData: 'Datos de prueba documental',
  uploadAttachment: 'Cargar adjunto pdf',
  testimonialEvidenceData: 'Datos de prueba testimonial',
  witnessesData: 'Datos de testigo/s',
  audienceReport: 'Cargar acta de audiencia',
  additionalEvidenceData: 'Datos de prueba adicional',
  addAttachmentDescription: 'Seleccione cómo quiere cargar el pdf:',
  addDigitalDocumentDescription: 'Seleccione cómo quiere cargar el documento digital:',
  uploadDigitalDocument: 'Cargar un documento digital'
});

i18next.addResourceBundle('es', 'InputWithOptions', {
  audienceDate: {
    title: 'Fecha',
    label: 'Fecha de audiencia'
  },
  audienceTime: {
    title: 'Hora',
    label: 'Hora de audiencia'
  }
});
