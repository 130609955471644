/* eslint-disable react/button-has-type */
import React from 'react';
import { string, bool, elementType } from 'prop-types';
import cn from 'classnames';

import { buttonTypePropType } from '#propTypes/buttons';

import LoadingWrapper from './components/LoadingWrapper';
import styles from './styles.module.scss';

function Button({
  children,
  type = 'button',
  disabled,
  loading,
  buttonClassName,
  iconClassName,
  loadingClassName,
  Icon,
  ...props
}) {
  return (
    <button className={buttonClassName} type={type} disabled={loading || disabled} {...props}>
      <LoadingWrapper loading={loading} loadingClassName={loadingClassName}>
        {!!Icon && <Icon className={cn(styles.icon, iconClassName)} />}
        {children}
      </LoadingWrapper>
    </button>
  );
}

Button.propTypes = {
  buttonClassName: string,
  disabled: bool,
  Icon: elementType,
  iconClassName: string,
  label: string,
  loading: bool,
  loadingClassName: string,
  type: buttonTypePropType
};

export default Button;
