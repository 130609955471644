import { push } from 'connected-react-router';

import AuthActions from '#redux/Auth/actions';
import LocalStorage from '#services/LocalStorageService';

export const mapAdmin = values => ({ ...values, admin: true });

export const submitAction = values => AuthActions.signIn(values);

export const redirectToLoginRedirectUrl = dispatch => {
  const loginRedirectUrl = LocalStorage.getLoginRedirectUrl();
  if (loginRedirectUrl) {
    dispatch(push(loginRedirectUrl));
    LocalStorage.removeLoginRedirectUrl();
  }
};
