import i18next from 'i18next';

i18next.addResources('es', 'TopBanner', {
  freeTrialOver: 'Su prueba gratuita ha finalizado',
  freeTrialDaysRemaining: 'Su prueba gratuita termina en {{ daysRemaining }} día{{ plural }}.',
  seeThePlans:
    '<span>Vea los <a>planes disponibles</a> para seguir su gestión profesional con Ubuntulaw.</span>',
  subscriptionOver: 'Su suscripción ha finalizado.',
  subscriptionPaused: 'No pudimos procesar su pago.',
  checkSubscriptionStatus:
    '<span>Consulte el estado de su plan en <a>su perfil</a> para seguir su gestión profesional con Ubuntulaw.</span>',
  scraperNotHealthy: 'La conexión con PJN se encuentra en mantenimiento. Reintente en unas horas.',
  systemPaused:
    '<span>Mientras tanto no se podrán importar nuevos expedientes y serán pausadas las actualizaciones del sistema.</span>'
});
