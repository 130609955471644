import i18next from 'i18next';

i18next.addResources('es', 'Event', {
  audience: 'Audiencia',
  reminder: 'Recordatorio',
  expiration: 'Vencimiento',
  other: 'Otro',
  undefined: 'Sin definir',
  audienceLink: 'Ir a la audiencia',
  legalCase: 'Expediente',
  legalAction: 'Actuación',
  legalEvidence: 'Prueba',
  reminderTemplate: 'Recordatorio: {{time}}',
  confirmDelete: '¿Eliminar evento?'
});

i18next.addResourceBundle('es', 'EventInputs', {
  eventTitle: {
    label: 'Título de evento*',
    placeholder: 'Ingresar un título'
  },
  eventType: {
    label: 'Tipo de evento*',
    placeholder: 'Elegir tipo'
  },
  reminder: {
    label: 'Recordatorio',
    placeholder: 'Elegir recordatorio'
  },
  audienceLink: {
    label: 'Link de audiencia',
    placeholder: 'Ingresar link'
  },
  legalCase: {
    label: 'Vincular*',
    subLabel: 'Expediente*',
    placeholder: 'Elegir expediente',
    searchPlaceholder: 'N° de expediente, carátula'
  },
  legalAction: {
    label: 'Vincular',
    subLabel: 'Actuación',
    placeholder: 'Elegir actuación',
    searchPlaceholder: 'Fecha de carga a PJN, descripción'
  }
});

i18next.addResources('es', 'ReminderOptions', {
  minutesBefore: '{{value}} min antes',
  hoursBefore: '{{value}} hs antes',
  daysBefore: '{{value}} día antes',
  weeksBefore: '{{value}} semana antes',
  monthsBefore: '{{value}} mes antes'
});
