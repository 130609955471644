import i18next from 'i18next';

i18next.addResources('es', 'EvidenceReport', {
  title: 'GENERAR INFORME DE PRUEBA',
  reportTitle: 'INFORME DE PRUEBA',
  coverSubtitle: 'ADJUNTOS DE {{type}}',
  coverSubtitle2: 'Parte {{part}}',
  choosePart: 'Elegir parte',
  part: 'Parte',
  nameAndSurname: 'Nombre y Apellido',
  address: 'Dirección',
  office: 'Oficina de notificaciones a cargo',
  diligenceStatus: 'Estado de diligenciamiento',
  fileNumber: 'N° Expediente',
  fileJurisdiction: 'Jurisdicción',
  fileDependency: 'Dependencia',
  fileStatus: 'Estado',
  fileCover: 'Carátula',
  informative: 'INFORMATIVA',
  expert: 'PERICIAL',
  testimonial: 'TESTIMONIAL',
  documentary: 'DOCUMENTAL',
  other: 'OTRAS PRUEBAS',
  informativeCover: 'PRUEBA INFORMATIVA',
  expertCover: 'PRUEBA PERICIAL',
  testimonyCover: 'PRUEBA TESTIMONIAL',
  documentaryCover: 'PRUEBA DOCUMENTAL',
  otherCover: 'OTRAS PRUEBAS',
  addressee: 'Oficio',
  state: 'Estado',
  type: 'Tipo',
  pdfTitle: 'Informe de Prueba',
  witness: 'TESTIGO',
  audienceDate: 'Fecha de audiencia',
  error: 'Debe seleccionar una parte para generar el informe de prueba',
  selectPart: 'Seleccionar parte',
  allParts: 'Todas las partes'
});
