import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  postNotifications: null,
  importNotifications: [],
  putNotification: null,
  configurateFileNotifications: null,
  updateParticularConfig: null,
  deleteParticularConfig: null
});

const reducerDescription = {
  primaryActions: [
    actions.IMPORT_NOTIFICATIONS,
    actions.POST_NOTIFICATIONS,
    actions.PUT_NOTIFICATION,
    actions.UPDATE_PARTICULAR_CONFIG,
    actions.DELETE_PARTICULAR_CONFIG
  ],
  override: {
    [actions.CONFIGURATE_FILE_NOTIFICATIONS]: onReadValue()
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
