import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';

import ModalActions from '#redux/Modal/actions';
import { useModal } from '#hooks/useModal';
import { ReactComponent as DefaultCloseIcon } from '#assets/close.svg';

export const useCustomModal = ({ modal, onClose, noScroll, CustomCloseIcon }) => {
  const dispatch = useDispatch();
  const { isOpen, closeModal } = useModal(modal);
  const clearAllModals = () => dispatch(ModalActions.clearModals());

  const clearModals = () => {
    onClose?.();
    clearAllModals();
  };

  const handleCloseModal = () => {
    onClose?.();
    closeModal(modal);
  };

  const prevIsOpen = useRef(isOpen);

  useEffect(() => {
    window.addEventListener('popstate', clearModals, false);
    if (isOpen) {
      noScroll.on();
    }
    if (!isOpen && prevIsOpen.current) {
      noScroll.off();
    }
    prevIsOpen.current = isOpen;
    return () => {
      noScroll.off();
      window.removeEventListener('popstate', clearModals);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsOpen, isOpen]);

  const CloseIcon = CustomCloseIcon || DefaultCloseIcon;

  return { isOpen, handleCloseModal, CloseIcon };
};
