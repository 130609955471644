import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import {
  cancelSubscription,
  changePaymentMethod,
  changeSubscriptionPlan,
  createSubscription,
  getSubscriptionPlan
} from '#services/SubscriptionService';

import { TARGETS } from './constants';
import { getWorkspaceAndGoToConfig, postCheckoutInjections } from './models';

export const actions = createTypes(
  completeTypes(
    [
      'CREATE_SUBSCRIPTION',
      'CANCEL_SUBSCRIPTION',
      'CHANGE_PAYMENT_METHOD',
      'CHANGE_SUBSCRIPTION_PLAN',
      'GET_SUBSCRIPTION_PLAN'
    ],
    []
  ),
  '@@SUBSCRIPTIONS'
);

const actionCreators = {
  createSubscription: (data, setCheckoutState, onSuccess) => ({
    type: actions.CREATE_SUBSCRIPTION,
    target: TARGETS.CREATE_SUBSCRIPTION,
    payload: data,
    service: createSubscription,
    injections: postCheckoutInjections(setCheckoutState, onSuccess)
  }),
  cancelSubscription: (data, onSuccess) => ({
    type: actions.CANCEL_SUBSCRIPTION,
    target: TARGETS.CANCEL_SUBSCRIPTION,
    payload: data,
    service: cancelSubscription,
    injections: [withPostSuccess(dispatch => getWorkspaceAndGoToConfig(onSuccess, dispatch))]
  }),
  changePaymentMethod: (data, setCheckoutState, onSuccess) => ({
    type: actions.CHANGE_PAYMENT_METHOD,
    target: TARGETS.CHANGE_PAYMENT_METHOD,
    payload: data,
    service: changePaymentMethod,
    injections: postCheckoutInjections(setCheckoutState, onSuccess)
  }),
  changeSubscriptionPlan: (data, setCheckoutState, onSuccess) => ({
    type: actions.CHANGE_SUBSCRIPTION_PLAN,
    target: TARGETS.CHANGE_SUBSCRIPTION_PLAN,
    payload: data,
    service: changeSubscriptionPlan,
    injections: postCheckoutInjections(setCheckoutState, onSuccess)
  }),
  getSubscriptionPlan: (data, onSuccess) => ({
    type: actions.GET_SUBSCRIPTION_PLAN,
    target: TARGETS.GET_SUBSCRIPTION_PLAN,
    payload: data,
    service: getSubscriptionPlan,
    injections: [withPostSuccess((dispatch, response) => onSuccess?.(response.data))]
  })
};

export default actionCreators;
