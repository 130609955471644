import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  createSubscription: null,
  cancelSubscription: null,
  changePaymentMethod: null,
  changeSubscriptionPlan: null,
  getSubscriptionPlan: null
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [
    actions.CREATE_SUBSCRIPTION,
    actions.CANCEL_SUBSCRIPTION,
    actions.CHANGE_PAYMENT_METHOD,
    actions.CHANGE_SUBSCRIPTION_PLAN,
    actions.GET_SUBSCRIPTION_PLAN
  ]
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
