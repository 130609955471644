import { withPostFailure, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import WorkspaceActions from '#redux/Workspace/actions';
import { CHECKOUT_STATES } from '#constants/checkout';
import { ROUTES } from '#constants/routes';
import { CREATE_SUBSCRIPTION_DELAY } from '#constants/subscriptions';

export const getWorkspaceAndGoToConfig = (onSuccess, dispatch) =>
  dispatch(
    WorkspaceActions.getCurrentWorkspace(() => {
      dispatch(push(ROUTES.WORKSPACE_CONFIGURATION.path));
      onSuccess?.();
    })
  );

export const postCheckoutInjections = (setCheckoutState, onSuccess) => [
  withPostSuccess(dispatch => {
    setCheckoutState(CHECKOUT_STATES.SUCCESS);
    setTimeout(() => getWorkspaceAndGoToConfig(onSuccess, dispatch), CREATE_SUBSCRIPTION_DELAY);
  }),
  withPostFailure(() => setCheckoutState(CHECKOUT_STATES.FAILED))
];
