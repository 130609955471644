import i18next from 'i18next';

i18next.addResources('es', 'UploadFileInput', {
  label: 'Cargar adjunto',
  placeholder: 'Cargar archivo'
});

i18next.addResourceBundle('es', 'AddAttachmentOptions', {
  ubuntu: {
    label: 'Cargar desde Ubuntulaw',
    description: 'Seleccione en la tabla la prueba que quiera cargar',
    tooltipMessage: 'En la tabla se muestran los pdf de las pruebas que figuran en actuaciones'
  },
  externalDevice: {
    label: 'Cargar desde dispositivo externo',
    tooltipMessage: 'Puede cargar archivos pdf guardados en su dispositivo'
  },
  continue: {
    label: 'Continuar sin cargar',
    description: 'Apretar siguiente para continuar'
  }
});
