import i18next from 'i18next';

i18next.addResources('es', 'ImportFail', {
  importError: 'Error de importación',
  title: 'El expediente no se ha podido importar, por favor vuelva a intentarlo',
  fileNotFound:
    'El expediente no se pudo importar. Por favor, revise si el expediente fue desvinculado de algún CUIT o si está fuera de trámite.',
  cancel: 'Cancelar',
  retry: 'Reintentar'
});
