import { completeTypes, createTypes, withPostFailure, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import * as AuthService from '#services/AuthService';
import LocalStorage from '#services/LocalStorageService';
import modalActions from '#redux/Modal/actions';
import legalCasesActions from '#redux/LegalCases/actions';
import { MODALS } from '#redux/Modal/constants';
import { VIEWS } from '#constants/auth0';
import { DEFAULT_ROUTE_PATH, ROUTES } from '#constants/routes';
import { removeAuthHeader, setAuthHeader } from '#config/api';
import NotificationActions from '#redux/Notifications/actions';
import SettingsActions from '#redux/Settings/actions';
import MixpanelService from '#services/MixpanelService';
import WorkspaceActions from '#redux/Workspace/actions';
import { redirectToLoginRedirectUrl } from '#utils/login';

import { INTERNAL_CODES, TARGETS, CREATE_CREDENTIAL_ERRORS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'SIGN_IN',
      'GET_CURRENT_USER',
      'CHECK_EMAIL',
      'UPDATE_USER',
      'CREATE_CREDENTIAL',
      'GET_CREDENTIALS',
      'DELETE_CREDENTIAL'
    ],
    ['SIGN_IN_PARAMS', 'LOG_OUT', 'SET_CREATE_CREDENTIAL_ERROR']
  ),
  '@@AUTH'
);

export const actionCreators = {
  init: () => dispatch => {
    const token = LocalStorage.getTokenManager();
    if (token && token.token) {
      setAuthHeader(token.token);
      dispatch(actionCreators.getCurrentUser());
      dispatch(actionCreators.getCredentials());
      dispatch(SettingsActions.getScraperHealth());
      dispatch(WorkspaceActions.getCurrentWorkspace());
    }
  },
  getCurrentUser: (
    onSuccess,
    shouldRedirect = true,
    shouldGetWorkspace = true,
    shouldDeleteLoginRedirectUrl = true
  ) => ({
    type: actions.GET_CURRENT_USER,
    target: TARGETS.CURRENT_USER,
    service: AuthService.getCurrentUser,
    injections: [
      withPostSuccess((dispatch, response) => {
        onSuccess?.(response?.data);
        if (shouldGetWorkspace) {
          dispatch(WorkspaceActions.getCurrentWorkspace());
        }
        const tokenManager = LocalStorage.getTokenManager();
        LocalStorage.setTokenManager({
          ...tokenManager,
          role: response?.data?.role?.type
        });
        const loginRedirectUrl = LocalStorage.getLoginRedirectUrl();
        if (loginRedirectUrl) {
          if (shouldRedirect) {
            dispatch(push(loginRedirectUrl));
          }
          if (shouldDeleteLoginRedirectUrl) {
            LocalStorage.removeLoginRedirectUrl();
          }
        }
      })
    ]
  }),
  // eslint-disable-next-line no-unused-vars
  signIn: ({ user }, redirectTo, oAuthLogoutCallback, redirecToParams) => ({
    type: actions.SIGN_IN,
    target: TARGETS.SIGN_IN,
    payload: user,
    service: AuthService.signIn,
    injections: [
      withPostSuccess((dispatch, response) => {
        // TODO: uncomment when email verification is implemented
        // if (user.email_verified) {
        LocalStorage.setTokenManager(response.data);
        LocalStorage.setLoginRedirectUrl(
          (redirectTo && `${redirectTo}?${redirecToParams}`) ||
            DEFAULT_ROUTE_PATH[response.data.role] ||
            ROUTES.HOME.path
        );
        const token = response?.data?.token;
        setAuthHeader(token);
        dispatch(
          actionCreators.getCurrentUser(
            data => {
              if (data?.workspace) {
                dispatch(WorkspaceActions.getCurrentWorkspace(() => redirectToLoginRedirectUrl(dispatch)));
              } else {
                redirectToLoginRedirectUrl(dispatch);
              }
              MixpanelService.identify(data.id);
            },
            false,
            false,
            false
          )
        );
        dispatch(SettingsActions.getScraperHealth());
        /* } else {
          dispatch(actionCreators.logout(oAuthLogoutCallback));
        }*/
      })
    ]
  }),
  logout: oAuthLogoutCallback => dispatch => {
    removeAuthHeader();
    LocalStorage.removeTokenManager();
    LocalStorage.removeCurrentStep();
    LocalStorage.removeWorkspaceRedirect();
    dispatch({
      type: actions.LOG_OUT,
      target: TARGETS.CURRENT_USER
    });
    oAuthLogoutCallback?.();
    if (!oAuthLogoutCallback) {
      dispatch(push(ROUTES.LOGIN.path));
    }
  },
  checkEmail: email => ({
    type: actions.CHECK_EMAIL,
    target: TARGETS.CHECK_EMAIL,
    service: AuthService.checkEmail,
    payload: { email },
    injections: [
      withPostSuccess((dispatch, { data }) => {
        dispatch(actionCreators.signInParams({ email, view: VIEWS.LOGIN, ...data }));
      }),
      withPostFailure((dispatch, { status }) => {
        if (status === 404) {
          dispatch(actionCreators.signInParams({ email, isSignUp: true, view: VIEWS.SIGNUP }));
        }
      })
    ]
  }),
  signInParams: params => ({
    type: actions.SIGN_IN_PARAMS,
    target: TARGETS.SIGN_IN_PARAMS,
    payload: params
  }),
  updateUser: ({
    user,
    shouldRedirect = true,
    redirectTo = ROUTES.HOME.path,
    onGettingUser,
    getUser = true
  }) => ({
    type: actions.UPDATE_USER,
    target: TARGETS.UPDATE_USER,
    payload: user,
    service: AuthService.updateUser,
    injections: [
      withPostSuccess(dispatch => {
        if (getUser) {
          dispatch(actionCreators.getCurrentUser(onGettingUser, shouldRedirect, false, false));
        }
        if (shouldRedirect) {
          dispatch(push(redirectTo));
        }
      }),
      withPostFailure(() => {
        toast.error(i18next.t('ProfileEdition:editProfileError'));
      })
    ]
  }),
  createCredential: (data, onSuccess, getCredentials = true) => ({
    type: actions.CREATE_CREDENTIAL,
    target: TARGETS.CREATE_CREDENTIAL,
    payload: data,
    service: AuthService.createCredential,
    injections: [
      withPostSuccess((dispatch, response) => {
        onSuccess?.();
        const credentialId = response?.data?.location?.split('/')?.pop();
        dispatch(actionCreators.getCurrentUser());
        if (getCredentials) {
          dispatch(actionCreators.getCredentials());
        }
        dispatch(legalCasesActions.importCases({ credentialId }));
        dispatch(modalActions.closeModal(MODALS.PJN_LOGIN_MODAL));
        dispatch(NotificationActions.postNotifications({ credentialId }));
      }),
      withPostFailure((dispatch, response) =>
        dispatch(
          actionCreators.setCreateCredentialError(
            INTERNAL_CODES[response?.data?.[0]?.internal_code] || CREATE_CREDENTIAL_ERRORS.SERVER_ERROR
          )
        )
      )
    ]
  }),
  getCredentials: () => ({
    type: actions.GET_CREDENTIALS,
    target: TARGETS.CURRENT_CREDENTIALS,
    service: AuthService.getCredentials
  }),
  deleteCredential: credentialId => ({
    type: actions.DELETE_CREDENTIAL,
    target: TARGETS.DELETE_CREDENTIAL,
    service: AuthService.deleteCredential,
    payload: credentialId,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.getCredentials());
      })
    ]
  }),
  setCreateCredentialError: error => ({
    type: actions.SET_CREATE_CREDENTIAL_ERROR,
    target: TARGETS.SET_CREATE_CREDENTIAL_ERROR,
    payload: error
  })
};

export default actionCreators;
