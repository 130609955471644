import { bool, node, string } from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

const LoadingWrapper = ({ loading, children, loadingClassName }) =>
  loading ? <loader className={cn(styles.loader, loadingClassName)} /> : children;

LoadingWrapper.propTypes = {
  children: node,
  loading: bool,
  loadingClassName: string
};

export default LoadingWrapper;
