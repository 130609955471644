import api from '#config/api';
import { getQueryString } from '#utils/parseUrl';

export const createWorkspace = data => api.post('/workspace', data);
export const getWorkspace = () => api.get('/workspace');
export const getWorkspaceById = id => api.get(`/workspace/${id}`);
export const acceptInvitation = id => api.post(`/workspace/${id}/accept`);
export const deleteInvitation = ({ workspace, inviteId }) =>
  api.delete(`/workspace/${workspace}/invite/${inviteId}`);
export const getInviteIdOfWorkspace = ({ workspace, inviteId }) =>
  api.get(`/workspace/${workspace}/invite/${inviteId}`);
export const getCredentialsOfWorkspace = () => api.get('/workspace/credentials');
export const getMembersOfWorkspace = data => api.get(`/workspace/members${getQueryString(data)}`);

export const inviteToWorkspace = data => api.post('/workspace/invite', data);
export const deleteMember = id => api.delete(`/workspace/members/${id}`);
export const abandonWorkspace = () => api.post('/workspace/abandon');
export const editWorkspace = workspace => api.put('/workspace', workspace);
export const deleteWorkspace = () => api.delete('/workspace');
export const assignUser = ({ legalCaseId, assigneeId }) =>
  api.post('/workspace/assign', { legalCaseId, assigneeId });
export const unassignUser = ({ legalCaseId, assigneeId }) =>
  api.post('/workspace/unassign', { legalCaseId, assigneeId });
