import i18next from 'i18next';

i18next.addResources('es', 'NotificationConfigurationModal', {
  title: 'NOTIFICACIONES VÍA EMAIL',
  description: 'Expediente {{- number}}',
  generalConfig: 'configuración general',
  generalFavoriteConfig: 'configuración general de favoritos',
  particularConfig: 'configuración personalizada',
  resetGeneralConfig: 'Restablecer configuración general',
  generalConfigRestored: 'Configuración restablecida',
  generalConfigOn: 'activada',
  generalConfigOff: 'desactivada',
  edit: 'Editar'
});

i18next.addResourceBundle('es', 'NotificationDescription', {
  cardsNotifications: 'Notificar las cédulas electrónicas del expediente',
  leaveNoteNotificationIsActive: 'Notificar las notas automáticas del expediente'
});
