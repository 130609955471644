import { getQueryString } from '#utils/parseUrl';
import api from '#config/api';

const createUser = user => api.post('/users', user);
const getUserById = id => api.get(`/users/${id}`);
const getUsers = query => api.get(`/users${getQueryString({ query })}`);
const updateUserById = ({ id, ...otherUserFields }) => api.put(`/admin/users/${id}`, { ...otherUserFields });
const deleteUserById = id => api.delete(`/admin/users/${id}`);
const sendWelcomeEmail = () => api.post('/send_welcome_email');

export default {
  createUser,
  getUserById,
  getUsers,
  updateUserById,
  deleteUserById,
  sendWelcomeEmail
};
