import { useMemo } from 'react';

import { WORKSPACE_STATUS, SUBSCRIPTION_STATUS } from '#constants/subscriptions';
import { isFreeTrialOver, isOnFreeTrial, onLessThanTenDaysOfFreeTrial } from '#models/workspace';

const getFreeTrialEndingStatus = workspace =>
  onLessThanTenDaysOfFreeTrial(workspace) ? WORKSPACE_STATUS.FREE_TRIAL_ENDING : WORKSPACE_STATUS.FREE_TRIAL;

const getFreeTrialStatus = (workspace, freeTrialOver) =>
  freeTrialOver ? WORKSPACE_STATUS.FREE_TRIAL_OVER : getFreeTrialEndingStatus(workspace);

const subscriptionToLawyerStatus = freeTrialOver => ({
  [SUBSCRIPTION_STATUS.CANCELLED]: freeTrialOver
    ? WORKSPACE_STATUS.SUBSCRIPTION_OVER
    : WORKSPACE_STATUS.SUBSCRIPTION_CANCELLED,
  [SUBSCRIPTION_STATUS.PAUSED]: WORKSPACE_STATUS.SUBSCRIPTION_PAUSED
});

export const useSubscriptionStatus = workspace => {
  const freeTrialOver = isFreeTrialOver(workspace);
  const { status: subscriptionStatus, plan } = workspace?.mercadoPagoSubscription || {};
  const freeTrial = isOnFreeTrial(workspace) || !plan;
  const status = useMemo(
    () =>
      freeTrial
        ? getFreeTrialStatus(workspace, freeTrialOver)
        : subscriptionToLawyerStatus(freeTrialOver)[subscriptionStatus] || WORKSPACE_STATUS.NORMAL,
    [workspace]
  );

  return status;
};
