import cn from 'classnames';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { string } from 'prop-types';

import styles from './styles.module.scss';
import { getIcon, isAlert, isWarning } from './utils';

function TopBanner({ status, title, subtitleKey, link }) {
  const Icon = getIcon(status);

  return (
    <div
      className={cn(styles.container, {
        '!bg-danger-50': isAlert(status),
        '!bg-warning-50': isWarning(status)
      })}>
      <Icon className={styles.icon} />
      <div className={styles.contentContainer}>
        <h3 className={styles.titleText}>{title}</h3>
        <Trans
          i18n={i18next}
          className={styles.subtitleText}
          i18nKey={subtitleKey}
          components={{
            a: <a href={link} className={styles.link} />,
            span: <span className={styles.subtitleText} />
          }}
        />
      </div>
    </div>
  );
}

TopBanner.propTypes = {
  link: string,
  status: string,
  subtitleKey: string,
  title: string
};

export default TopBanner;
