import { getCurrentRoute } from '#utils/routes';
import { DEFAULT_ROUTE_PATH, ROUTES } from '#constants/routes';
import { SUBSCRIPTION_STATUS } from '#constants/subscriptions';

import { hasAccess } from './session';
import { isFreeTrialOver, isOnFreeTrial } from './workspace';

const getLoginPath = (pathname, search) => {
  const searchQueryParam = search ? `&${search.substring(1)}` : '';
  return {
    pathname: ROUTES.LOGIN.path,
    search: pathname === '/' ? '' : `?redirectTo=${pathname}${searchQueryParam}`
  };
};

export const mapRedirects = (tokenManager, pathname, search) => {
  const defaultPath = DEFAULT_ROUTE_PATH[tokenManager?.role];
  const login = getLoginPath(pathname, search);

  return { login, defaultPath };
};

const notAvailableOnFreeTrial = ({ subscriptionPaused, onFreeTrial, freeTrialOver, availableOnFreeTrial }) =>
  ((onFreeTrial && freeTrialOver) || subscriptionPaused) && !availableOnFreeTrial;

// eslint-disable-next-line complexity
export const getLoggedUserRedirect = ({
  privateRoute,
  access,
  defaultPath,
  didOnboarding,
  needsOnboarding,
  onFreeTrial,
  freeTrialOver,
  availableOnFreeTrial,
  subscriptionPaused,
  workspace
}) =>
  (needsOnboarding && !workspace && ROUTES.LAWYER_ONBOARDING.path) ||
  (needsOnboarding && !didOnboarding && ROUTES.LAWYER_ONBOARDING.path) ||
  (workspace &&
    (!(privateRoute && access) ||
      notAvailableOnFreeTrial({ subscriptionPaused, onFreeTrial, freeTrialOver, availableOnFreeTrial })) &&
    defaultPath);

const hasDoneOnboarding = user => !user || !!user?.didOnboarding;
const hasWorkspace = user => !user || !!user?.workspace;

const getRedirect = ({
  logged,
  login,
  privateRoute,
  access,
  defaultPath,
  needsOnboarding,
  didOnboarding,
  onFreeTrial,
  freeTrialOver,
  availableOnFreeTrial,
  subscriptionPaused,
  publicRoute,
  workspace
}) =>
  logged
    ? getLoggedUserRedirect({
        privateRoute,
        access,
        defaultPath,
        needsOnboarding,
        didOnboarding,
        onFreeTrial,
        freeTrialOver,
        availableOnFreeTrial,
        subscriptionPaused,
        workspace
      })
    : !publicRoute && login;

export const getCurrentRouteRedirect = ({
  tokenManager,
  pathname,
  search,
  currentUser,
  currentWorkspace
}) => {
  const {
    supportedRoles = [],
    publicRoute,
    privateRoute = !publicRoute,
    needsOnboarding = true,
    availableOnFreeTrial = true,
    noRedirect
  } = getCurrentRoute(pathname) || {};

  const logged = !!tokenManager;
  const didOnboarding = hasDoneOnboarding(currentUser);
  const onFreeTrial = isOnFreeTrial(currentWorkspace);
  const freeTrialOver = isFreeTrialOver(currentWorkspace);
  const subscriptionPaused = currentWorkspace?.mercadoPagoSubscription?.status === SUBSCRIPTION_STATUS.PAUSED;
  const access = publicRoute || hasAccess(tokenManager, supportedRoles, didOnboarding, pathname);

  const { login, defaultPath } = mapRedirects(tokenManager, pathname, search);

  return (
    !noRedirect &&
    getRedirect({
      logged,
      login,
      privateRoute,
      access,
      defaultPath,
      needsOnboarding,
      didOnboarding,
      onFreeTrial,
      freeTrialOver,
      availableOnFreeTrial,
      subscriptionPaused,
      publicRoute,
      workspace: hasWorkspace(currentUser)
    })
  );
};
