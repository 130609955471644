import i18next from 'i18next';

i18next.addResourceBundle('es', 'Inputs', {
  evidenceType: {
    label: 'Tipo de prueba',
    placeholder: 'Seleccionar tipo de prueba'
  },
  part: {
    label: 'Parte:',
    placeholder: 'Seleccionar parte'
  },
  state: {
    label: 'Estado',
    placeholder: 'Seleccionar estado'
  },
  type: {
    label: 'Tipo',
    placeholder: 'Ingresar tipo'
  },
  points: {
    label: 'Puntos periciales',
    placeholder: 'Ingresar puntos'
  },
  nameAndSurname: {
    label: 'Nombre y Apellido',
    placeholder: 'Ingresar nombre y apellido'
  },
  email: {
    label: 'Email',
    placeholder: 'Ingresar email'
  },
  contactNumber: {
    label: 'Número de contacto',
    placeholder: 'Ingresar número'
  },
  freeField: {
    label: 'Campo libre',
    placeholder: 'Ingresar campo libre'
  },
  addressee: {
    label: 'Destinatario',
    placeholder: 'Ingresar destinatario'
  },
  order: {
    label: 'Pedido',
    placeholder: 'Ingresar pedido'
  },
  detail: {
    label: 'Detalle',
    placeholder: 'Ingresar detalle'
  },
  date: {
    label: 'Fecha de audiencia',
    placeholder: 'Seleccionar fecha'
  },
  time: {
    label: 'Hora de audiencia',
    placeholder: 'Seleccionar hora'
  },
  address: {
    label: 'Dirección',
    placeholder: 'Ingresar dirección'
  },
  office: {
    label: 'Oficina de notificaciones a cargo',
    placeholder: 'Ingresar oficina'
  },
  diligenceStatus: {
    label: 'Estado de diligenciamiento',
    placeholder: 'Seleccionar estado'
  },
  fileState: {
    label: 'Etapa del proceso judicial:',
    placeholder: 'Seleccionar etapa'
  },
  audienceLink: {
    label: 'Link de audiencia',
    placeholder: 'Ingresar link'
  },
  callNumber: {
    label: 'Número de llamado',
    placeholder: 'Seleccionar número de llamado'
  },
  password: {
    label: 'Contraseña',
    placeholder: 'Ingrese su contraseña'
  }
});
