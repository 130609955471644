import { useSelector } from 'react-redux';

export const usePersonalInformation = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  return {
    name: `${currentUser?.name} ${currentUser?.surname}`,
    email: currentUser?.email,
    picture: currentUser?.picture
  };
};
