/* eslint-disable no-template-curly-in-string */
import i18next from 'i18next';

import { BULLET_TYPES } from './components/NotificationsInformation/constants';

const { GENERAL_CONFIGURATION, INDIVIDUAL_CONFIGURATION } = BULLET_TYPES;

i18next.addResources('es', 'Profile', {
  myProfile: 'MI PERFIL',
  registrationInfo: 'Información de registro',
  editName: 'Editar nombre',
  subscription: 'Suscripción',
  freeTrial: 'Prueba gratuita',
  freeTrialOver: 'Prueba gratuita finalizada',
  yourFreeTrialExpires: 'Su prueba gratuita expira el',
  yourFreeTrialExpired: 'Su prueba gratuita expiró el',
  seePlans: 'Ver planes',
  requiredField: 'Este campo es obligatorio',
  name: 'Nombre*',
  surname: 'Apellido*',
  changeName: 'Cambiar nombre',
  monthlyNextPayment: 'Su próximo pago de <dec>${{price}}</dec> es el <dec>{{nextPaymentDate}}</dec>',
  annualNextPayment: 'Su próximo pago es el <dec>{{nextPaymentDate}}</dec>',
  monthlyPayment: 'Pago mensual',
  annualPayment: 'Pago anual',
  cancelSubscription: 'Cancelar suscripción',
  paidWithMP: 'Pago a través Mercado Pago.',
  changePaymentMethod: 'Modificar medio de pago',
  subscriptionCancelled: 'Suscripción cancelada',
  subscriptionOver: 'Suscripción finalizada',
  subscriptionPaused: 'Suscripción suspendida',
  canContinueUsingUntil: 'Puede continuar utilizando Ubuntulaw hasta el <dec>{{endDate}}</dec>',
  yourSubscriptionIsOver: 'Su suscripción ha finalizado.',
  getANewPlan: 'Adquiera un nuevo plan para continuar utilizando Ubuntulaw.',
  couldNotProcessPayment: 'No pudimos procesar su pago.',
  checkPaymentMethod: 'Revise los datos del medio de pago para reanudar su suscripción.',
  workspaceName: 'Nombre de espacio de trabajo*',
  notifications: 'Notificaciones vía email',
  id: 'Cédulas electrónicas',
  proceedings: 'Actuaciones',
  notes: 'Notas automáticas',
  account: 'Cuenta',
  logout: 'Cerrar sesión',
  [`${GENERAL_CONFIGURATION}bullet`]: '\u2022 Configuración general para\nrecibir novedades de ',
  [`${INDIVIDUAL_CONFIGURATION}bullet`]: '\u2022 Para ',
  [`${GENERAL_CONFIGURATION}textBold`]: 'todos ',
  [`${INDIVIDUAL_CONFIGURATION}textBold`]: 'configuración individual ',
  [`${GENERAL_CONFIGURATION}textNormal`]: 'sus ',
  [`${INDIVIDUAL_CONFIGURATION}textNormal`]: 'por expediente asignado ir a ',
  [`${GENERAL_CONFIGURATION}finalText`]: 'expedientes asignados.',
  [`${INDIVIDUAL_CONFIGURATION}finalText`]: 'lista de causas.'
});
