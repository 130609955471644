import i18next from 'i18next';

i18next.addResources('es', 'Validations', {
  invalidEmail: 'El email ingresado es inválido',
  required: 'Requerido',
  invalidCuit: 'El CUIT ingresado es inválido'
});

i18next.addResources('en', 'Validations', {
  invalidEmail: 'The entered email is invalid',
  required: 'Required',
  invalidCuit: 'The entered CUIT is invalid'
});

i18next.addResources('es', 'Buttons', {
  selected: 'Seleccionado',
  select: 'Seleccionar',
  viewPDF: 'Ver PDF',
  notes: 'Notas',
  favorite: 'Destacado',
  notFavorite: 'Sin destacar',
  notify: 'Notificar',
  notifyActive: 'Notificar activo',
  import: 'Importar',
  imported: 'Importado',
  addNote: 'Dejar nota',
  removeNote: 'Quitar nota',
  comment: 'Comentar',
  schedule: 'Agendar',
  edit: 'Editar',
  options: 'Opciones',
  delete: 'Eliminar',
  viewData: 'Ver datos',
  save: 'Guardar',
  cancel: 'Cancelar',
  addEmail: 'Agregar email',
  filters: 'Filtros',
  applyFilters: 'Filtrar',
  attachAction: 'Vincular actuación',
  create: 'Crear',
  goToAudience: 'Ir a la audiencia',
  waiting: 'En espera',
  download: 'Descargar',
  next: 'Siguiente',
  close: 'Cerrar',
  accept: 'Aceptar',
  downloadDocument: 'Descargar documento'
});

i18next.addResources('es', 'Dates', {
  today: 'Hoy',
  yesterday: 'Ayer',
  tomorrow: 'Mañana',
  week: 'Semana',
  month: 'Mes',
  day: 'Día',
  of: 'de'
});

i18next.addResources('es', 'CookieConsent', {
  message: 'Este sitio web utiliza cookies para mejorar la experiencia del usuario.',
  accept: 'Acepto'
});
