import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { TARGETS } from './constants';

const defaultState = completeState({
  createWorkspace: null,
  currentWorkspace: null,
  getWorkspace: null,
  acceptInvitation: null,
  currentInviteId: null,
  deleteInvitation: null,
  workspaceCredentials: [],
  workspaceMembers: [],
  inviteToWorkspace: null,
  membersOfWorkspace: [],
  credentialsOfWorkspace: [],
  addLeaveNote: null
});

const reducerDescription = {
  primaryActions: [
    actions.CREATE_WORKSPACE,
    actions.GET_CURRENT_WORKSPACE,
    actions.GET_WORKSPACE,
    actions.ACCEPT_INVITATION,
    actions.DELETE_INVITATION,
    actions.GET_INVITE_ID_OF_WORKSPACE,
    actions.GET_CREDENTIALS_OF_WORKSPACE,
    actions.GET_MEMBERS_OF_WORKSPACE,
    actions.INVITE_TO_WORKSPACE,
    actions.DELETE_MEMBER,
    actions.ABANDON_WORKSPACE,
    actions.EDIT_WORKSPACE,
    actions.DELETE_WORKSPACE,
    actions.ASSIGN_USER,
    actions.UNASSIGN_USER
  ],
  override: {
    [actions.ADD_LEAVE_NOTE]: onReadValue(({ payload: id }, state) => {
      const currentWorkspace = state[TARGETS.CURRENT_WORKSPACE];
      const leaveNoteCases = currentWorkspace.leaveNoteCases || [];
      return {
        ...currentWorkspace,
        leaveNoteCases: leaveNoteCases.includes(id)
          ? leaveNoteCases.filter(item => item !== id)
          : [...leaveNoteCases, id]
      };
    })
  }
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
