import i18next from 'i18next';

i18next.addResources('es', 'Mixpanel', {
  accountCreated: 'Cuenta Creada',
  credentialLoaded: 'Credencial Cargada',
  fileImported: 'Expediente Importado',
  pdfOpened: 'Actuación/cédula Abierta',
  checkoutSuccessful: 'Checkout Exitoso',
  cancelledSubscription: 'Suscripción Cancelada',
  workspaceCreated: 'Espacio de Trabajo Creado',
  taskCreated: 'Tarea Creada',
  taskUpdated: 'Tarea Actualizada',
  evidenceUploaded: 'Prueba Creada',
  calendarOpened: 'Calendario Abierto',
  leaveNote: 'Nota Automática Activada',
  assignment: 'Asignación Realizada',
  actions: 'Actuación',
  notifications: 'Cédula',
  evidences: 'PDF de Prueba',
  monthly: 'Mensual',
  annual: 'Anual',
  basicmonthly: 'Suscripción Básica Mensual',
  basicannual: 'Suscripción Básica Anual',
  standardmonthly: 'Suscripción Estándar Mensual',
  standardannual: 'Suscripción Estándar Anual',
  premiummonthly: 'Suscripción Prémium Mensual',
  premiumannual: 'Suscripción Prémium Anual',
  notSubscribed: 'No Suscripto'
});
