import { bool, shape, string } from 'prop-types';

import styles from '../../../styles.module.scss';

function SubMenu({ item, subMenuOpen }) {
  return (
    <>
      {item.subMenus && subMenuOpen && (
        <div className={styles.subMenuContainer}>
          {item.subMenus?.map(sub => (
            <MenuItem
              key={sub.name}
              item={sub}
              itemClassName={styles.subItem}
              iconClassName={styles.subItemIcon}
              labelClassName={styles.subMenuLabel}
            />
          ))}
        </div>
      )}
    </>
  );
}

SubMenu.propTypes = {
  item: shape({
    name: string.isRequired,
    iconOn: string.isRequired,
    iconOff: string.isRequired,
    secondary: bool
  }).isRequired,
  subMenuOpen: bool
};

export default SubMenu;
