import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import { origin } from '#utils/window';
import authActions from '#redux/Auth/actions';

export const useLogout = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const handleLogout = (returnTo = origin()) => {
    dispatch(authActions.logout(() => logout(returnTo)));
  };

  return handleLogout;
};
