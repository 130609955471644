import i18next from 'i18next';

i18next.addResources('es', 'SubscriptionCancellation', {
  title: '¿Cuál es el motivo de cancelación?',
  subtitle: 'Queremos conocerlo para mejorar',
  highPrice: 'El precio de la suscripción es muy alto',
  foundBetterPlatform: 'Encontré una plataforma que me gusta más',
  functionsNotUseful: 'Las funciones de Ubuntulaw no me son útiles',
  other: 'Otro',
  requiredCancelReason: 'Seleccione un motivo de cancelación',
  confirmCancel: 'Entiendo que cancelo mi suscripción el día de fecha',
  otherReason: 'Escriba el motivo de cancelación aquí...',
  cancelSubscription: 'Cancelar suscripción',
  cancellationSuccessful: 'Ha cancelado su suscripción con éxito.'
});
