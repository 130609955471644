export const TARGETS = {
  SELECTED_CASES: 'selectedCases',
  IMPORTED_CASES: 'importedCases',
  IMPORT_CASES: 'importCases',
  IMPORT_CASE: 'importCase',
  CURRENT_CASE: 'currentCase',
  IMPORT_ACTIONS: 'importActions',
  IMPORT_PARTICIPANTS: 'importParticipants',
  IMPORT_EXPERTS: 'importExperts',
  IMPORT_PROSECUTORS: 'importProsecutors',
  IMPORT_INVOLVED_FILES: 'importInvolvedFiles',
  UPDATED_PARTICIPANTS: 'importParticipants',
  UPDATED_EXPERTS: 'importExperts',
  UPDATE_LEGAL_CASE: 'updateLegalCase',
  IMPORT_EXPERT_EVIDENCE: 'importExpertEvidence',
  IMPORT_INFORMATIVE_EVIDENCE: 'importInformativeEvidence',
  IMPORT_DOCUMENTARY_EVIDENCE: 'importDocumentaryEvidence',
  IMPORT_TESTIMONY_EVIDENCE: 'importTestimonyEvidence',
  IMPORT_OTHER_EVIDENCE: 'importOtherEvidence',
  UPLOAD_EVIDENCE: 'uploadEvidence',
  EVIDENCE_DATA: 'evidenceData',
  DELETE_EVIDENCE: 'deleteEvidence',
  UPDATE_EVIDENCE: 'updateEvidence',
  UPDATE_LEGAL_ACTION: 'updateLegalAction',
  IMPORT_NOTES: 'importNotes',
  SEARCH_CASE: 'searchCase',
  ADD_CASE: 'addCase',
  EVIDENCE_BY_PART: 'evidenceByPart',
  LEAVE_NOTE: 'leaveNote',
  ASSIGNEES_ERROR: 'assigneesError'
};

export const LEGAL_CASES_ERRORS = {
  IMPORT_LIMIT: 'importLimit'
};

export const INTERNAL_CODES = {
  [LEGAL_CASES_ERRORS.IMPORT_LIMIT]: '1169'
};
