import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer, Slide } from 'react-toastify';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import store from '#redux/store';
import { SCOPE } from '#constants/auth0';
import { TOAST_POSITIONS } from '#constants/toast';
import MixpanelService from '#services/MixpanelService';

import Routes from './components/Routes';
import ToastCloseButton from './components/ToastCloseButton';

const App = () => {
  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
    MixpanelService.init();
  }, []);
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_JWKS_AUDIENCE}
      scope={SCOPE}>
      <Provider store={store}>
        <Routes />
        <ToastContainer
          position={TOAST_POSITIONS.BOTTOM_RIGHT}
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeButton={ToastCloseButton}
          transition={Slide}
        />
      </Provider>
    </Auth0Provider>
  );
};

export default hot(App);
