import i18next from 'i18next';
import { getNumberOfDaysBetween } from '@Zerf-dev/utils';
import { useMemo } from 'react';

import { currentDate } from '#utils/date';
import { ROUTES } from '#constants/routes';
import { BANNER_STATUS } from '#components/TopBanner/constants';
import { TOP_BANNER_STATUS } from '#constants/topBanner';
import { getFreeTrialEndDate } from '#models/workspace';

import { useSubscriptionStatus } from './useSubscriptionStatus';

const getStatusProps = (status, daysRemaining) =>
  ({
    [TOP_BANNER_STATUS.NORMAL]: {
      hideBanner: true
    },
    [TOP_BANNER_STATUS.FREE_TRIAL]: {
      hideBanner: true
    },
    [TOP_BANNER_STATUS.SUBSCRIPTION_CANCELLED]: {
      hideBanner: true
    },
    [TOP_BANNER_STATUS.FREE_TRIAL_ENDING]: {
      bannerStatus: BANNER_STATUS.INFO,
      bannerTitle: i18next.t('TopBanner:freeTrialDaysRemaining', {
        daysRemaining,
        plural: daysRemaining > 1 ? 's' : ''
      }),
      bannerSubtitleKey: 'TopBanner:seeThePlans',
      bannerLink: ROUTES.SUBSCRIPTION_PLANS.path
    },
    [TOP_BANNER_STATUS.FREE_TRIAL_OVER]: {
      bannerStatus: BANNER_STATUS.ALERT,
      bannerTitle: i18next.t('TopBanner:freeTrialOver'),
      bannerSubtitleKey: 'TopBanner:seeThePlans',
      bannerLink: ROUTES.SUBSCRIPTION_PLANS.path
    },
    [TOP_BANNER_STATUS.SUBSCRIPTION_OVER]: {
      bannerStatus: BANNER_STATUS.INFO,
      bannerTitle: i18next.t('TopBanner:subscriptionOver'),
      bannerSubtitleKey: 'TopBanner:seeThePlans',
      bannerLink: ROUTES.SUBSCRIPTION_PLANS.path
    },
    [TOP_BANNER_STATUS.SUBSCRIPTION_PAUSED]: {
      bannerStatus: BANNER_STATUS.ALERT,
      bannerTitle: i18next.t('TopBanner:subscriptionPaused'),
      bannerSubtitleKey: 'TopBanner:checkSubscriptionStatus',
      bannerLink: ROUTES.PROFILE.path
    },
    [TOP_BANNER_STATUS.SCRAPER_NOT_HEALTHY]: {
      bannerStatus: BANNER_STATUS.WARNING,
      bannerTitle: i18next.t('TopBanner:scraperNotHealthy'),
      bannerSubtitleKey: 'TopBanner:systemPaused',
      bannerLink: ROUTES.PROFILE.path
    }
  }[status]);

// TODO: delete when scraper is healthy
// eslint-disable-next-line no-unused-vars
export const useTopBanner = ({ currentWorkspace, scraperHealth: { isHealthy } }) => {
  const status = useSubscriptionStatus(currentWorkspace);
  const daysRemaining = useMemo(() =>
    getNumberOfDaysBetween(currentDate(), getFreeTrialEndDate(currentWorkspace), [currentWorkspace])
  );

  if (!isHealthy) {
    return getStatusProps(TOP_BANNER_STATUS.SCRAPER_NOT_HEALTHY);
  }
  return getStatusProps(status, daysRemaining);
};
