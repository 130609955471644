import i18next from 'i18next';

i18next.addResources('es', 'TransitionPage', {
  processingPayment: 'Procesando su pago...',
  thisCanTakeAWhile: 'Esto puede tardar unos segundos.',
  paymentSuccess: 'Pago exitoso',
  redirectingToUbuntu: 'Lo redirigiremos a Ubuntulaw...',
  paymentFailed: 'Error al procesar el pago',
  tryAgain: 'Por favor revise los datos ingresados.',
  goBackToCheckout: 'Volver al checkout',
  updatingPaymentMethod: 'Actualizando datos...',
  paymentMethodUpdated: 'Cambio realizado con éxito',
  paymentMethodUpdateFailed: 'Error al actualizar datos'
});
