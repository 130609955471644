import i18next from 'i18next';

import { SUBSCRIPTION_PLANS } from '#constants/subscriptions';

const { BASIC, STANDARD, PREMIUM } = SUBSCRIPTION_PLANS;

i18next.addResources('es', 'SubscriptionPlans', {
  benefit1: 'Beneficio 1',
  benefit2: 'Beneficio 2',
  benefit3: 'Beneficio 3',
  title: 'Mejore su gestión profesional con Ubuntulaw',
  [`${BASIC}Subscription`]: 'Suscripción Básica',
  [`${STANDARD}Subscription`]: 'Suscripción Estándar',
  [`${PREMIUM}Subscription`]: 'Suscripción Prémium',
  annualTotalPrice: 'Total anual de',
  perMonth: '/ mes',
  planDetailLink: 'Ver detalles de la suscripción',
  priceSign: '$',
  subscribe: 'Suscribirme',
  totalPayment: 'Pago final mensual',
  totalPrice: 'Total anual de $',
  annual: 'Anual',
  monthly: 'Mensual',
  send: 'Enviar',
  teamSubscription: 'Suscripción de equipo legal',
  comingSoon: '¡Próximamente!',
  notifyDescription: 'Adquiera un plan especial para todo su equipo',
  mercadoPagoPayment: 'Pago a través de Mercado Pago',
  filesImport: 'Importación de {{amount}} expedientes (Públicos y privados en total)',
  collaborationWithUsers: 'Colaboración con usuarios',
  individualFilesManagement: 'Gestión integral de expedientes',
  allBenefitsInIndividualVersion: 'Todos los beneficios incluidos en la versión individual',
  multiplePJNCredentials: 'Sumar múltiples credenciales PJN',
  userCollaboration: 'Colaboración entre varios usuarios',
  receiveNews: 'Recibir novedades',
  notifyWhenAvailable: '¡Gracias! Le notificaremos por mail cuando el plan esté disponible.',
  subscribed: 'Suscripto',
  includedIVA: '(IVA Incluido)'
});
