import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import {
  deleteParticularNotificationConfig,
  importNotifications,
  postNotification,
  putNotification,
  updateParticularNotificationConfig
} from '#services/NotificationService';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'IMPORT_NOTIFICATIONS',
      'POST_NOTIFICATIONS',
      'PUT_NOTIFICATION',
      'UPDATE_PARTICULAR_CONFIG',
      'DELETE_PARTICULAR_CONFIG'
    ],
    ['CONFIGURATE_FILE_NOTIFICATIONS']
  ),
  '@@NOTIFICATIONS'
);

const actionCreators = {
  postNotifications: data => ({
    type: actions.POST_NOTIFICATIONS,
    target: TARGETS.POST_NOTIFICATIONS,
    service: postNotification,
    payload: data
  }),
  importNotifications: data => ({
    type: actions.IMPORT_NOTIFICATIONS,
    target: TARGETS.IMPORT_NOTIFICATIONS,
    service: importNotifications,
    payload: data
  }),
  putNotification: ({ getFiles, ...data }) => ({
    type: actions.PUT_NOTIFICATION,
    target: TARGETS.PUT_NOTIFICATION,
    service: putNotification,
    payload: data,
    injections: [withPostSuccess(getFiles)]
  }),
  configurateFileNotifications: data => ({
    type: actions.CONFIGURATE_FILE_NOTIFICATIONS,
    target: TARGETS.CONFIGURATE_FILE_NOTIFICATIONS,
    payload: data
  }),
  updateParticularConfig: (data, getFiles) => ({
    type: actions.UPDATE_PARTICULAR_CONFIG,
    target: TARGETS.UPDATE_PARTICULAR_CONFIG,
    service: updateParticularNotificationConfig,
    payload: data,
    injections: [
      withPostSuccess(() => {
        getFiles?.();
      })
    ]
  }),
  deleteParticularConfig: (id, getFiles) => ({
    type: actions.DELETE_PARTICULAR_CONFIG,
    target: TARGETS.DELETE_PARTICULAR_CONFIG,
    service: deleteParticularNotificationConfig,
    payload: id,
    injections: [
      withPostSuccess(() => {
        getFiles?.();
      })
    ]
  })
};

export default actionCreators;
