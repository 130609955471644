import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import Button from '#components/Button';
import { useHover } from '#hooks/useHover';

function IconButton({
  children,
  onClick = () => null,
  label,
  containerClassName,
  buttonClassName,
  labelClassName,
  isHelp,
  bottomLabel,
  ...props
}) {
  const [isShown, setIsShown] = useHover();

  return (
    <div
      className={cn('relative', containerClassName)}
      onMouseEnter={setIsShown(true)}
      onMouseLeave={setIsShown(false)}>
      <Button
        className={cn(
          'flex h-9 w-9 flex-row items-center justify-center rounded-lg hover:bg-hover',
          buttonClassName,
          {
            'hover:!bg-neutral-200': isHelp
          }
        )}
        onClick={onClick}
        {...props}>
        {children}
      </Button>
      {isShown && label && (
        <div
          className={cn(
            'bold absolute bottom-11 left-1/2 z-10 w-max -translate-x-2/4 rounded bg-neutral-700 p-1 text-sm text-white content-[""]',
            'after:absolute after:left-1/2 after:-m-1 after:border-4 after:border-solid after:border-transparent  after:content-[""]',
            bottomLabel
              ? 'after:bottom-[calc(100%+4px)] after:border-b-neutral-700'
              : 'after:top-[calc(100%+4px)] after:border-t-neutral-700',
            labelClassName
          )}>
          {label}
        </div>
      )}
    </div>
  );
}

IconButton.propTypes = {
  bottomLabel: bool,
  buttonClassName: string,
  containerClassName: string,
  isHelp: bool,
  label: string,
  labelClassName: string,
  onClick: func
};

export default IconButton;
