import api from '#config/api';

export const signIn = user => api.post('/sign_in', user);

export const getCurrentUser = () => api.get('/me');

export const checkEmail = ({ email }) => api.get(`/check_email?email=${email}`);

export const updateUser = user => api.put('/me', user);

export const createCredential = credentials => api.post('/credentials', credentials);

export const getCredentials = () => api.get('/credentials');

export const deleteCredential = id => api.delete(`/credentials/${id}`);
