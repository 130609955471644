import i18next from 'i18next';

i18next.addResources('es', 'Credentials', {
  credential: 'Credencial',
  fullName: 'Nombre y apellido',
  cuit: 'CUIT',
  portal: 'Portal',
  noCredentials: 'Aún no tiene credenciales cargadas.',
  connectToStart: 'Conecte su credencial del Poder Judicial de la Nación para comenzar.'
});
