import cn from 'classnames';
import { bool, shape, string } from 'prop-types';

import styles from '../styles.module.scss';

import ArrowDown from '#assets/arrow-down.svg';

function SubmenuTitle({ item, labelClassName, isSelected, subMenuClassName, subMenuOpen, openSubMenu }) {
  const { titleComponent: TitleComponent } = item;
  return (
    <div className={cn(styles.subMenu, subMenuClassName)}>
      {TitleComponent ? (
        <TitleComponent isSelected={isSelected} />
      ) : (
        <h2
          className={cn(labelClassName, {
            [styles.subMenuTitle]: !isSelected,
            [styles.subMenuTitleClicked]: isSelected,
            [styles.secondary]: !!item?.secondary
          })}>
          {item.name}
        </h2>
      )}
      {item.subMenus && (
        <img
          src={ArrowDown}
          className={cn(styles.arrow, { [styles.rotate]: subMenuOpen })}
          onClick={openSubMenu}
        />
      )}
    </div>
  );
}

SubmenuTitle.propTypes = {
  isSelected: bool,
  item: shape({
    secondary: bool
  }),
  labelClassName: string,
  openSubMenu: bool,
  subMenuClassName: string,
  subMenuOpen: bool
};

export default SubmenuTitle;
