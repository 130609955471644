import i18next from 'i18next';

i18next.addResources('es', 'ExpertReportTable', {
  expertReport: 'Informe pericial',
  expertiseObjection: 'Impugn. de pericia',
  points: 'Puntos periciales',
  type: 'Tipo',
  part: 'Parte',
  status: 'Estado',
  expertData: 'Perito',
  searchBarPlaceholder: 'Puntos periciales, tipo'
});
