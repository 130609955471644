import cn from 'classnames';
import { useRef, useEffect } from 'react';

import SideModal from '#components/SideModal';
import { useModal } from '#hooks/useModal';
import { isSmallDevice } from '#utils/screenSize';
import { MODALS } from '#redux/Modal/constants';
import { SIDES } from '#constants/sides';
import { useClickedOutside } from '#hooks/useClickedOutside';

import styles from './styles.module.scss';

export const useSideBar = ({ canBeHided = true }) => {
  const ref = useRef();
  const clickedOutside = useClickedOutside(ref);
  const { isOpen, closeModal, openModal } = useModal(MODALS.SIDE_BAR);
  const isModal = canBeHided || isSmallDevice();
  const Container = isModal ? SideModal : 'div';

  useEffect(() => {
    if (clickedOutside && isOpen) {
      closeModal();
    }
  }, [clickedOutside, isOpen]);

  const props = isModal
    ? { side: SIDES.LEFT, modal: MODALS.SIDE_BAR, isOpen, className: styles.sideBar }
    : {
        className: cn(styles.sideBar, {
          [styles.sideBarCollapse]: !isOpen
        })
      };

  return {
    ref,
    isOpen,
    closeModal,
    openModal,
    Container,
    props
  };
};
