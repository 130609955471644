import i18next from 'i18next';

i18next.addResources('es', 'UbuntuWelcome', {
  welcomeToUbuntu: 'Le damos la bienvenida a Ubuntulaw',
  fileImport: 'Importe expedientes',
  addPublicFile: 'Agregue expedientes públicos',
  workAsATeam: 'Trabaje en equipo',
  tryItFree30Days: 'Probalo 30 días gratis',
  connectYourCredentialOf: 'Conectá tu credencial del ',
  pjn: 'Poder Judicial de la Nación',
  toStartFollowingYourCases: ' para comenzar a seguir todos tus casos.',
  notNow: 'Ahora no',
  continue: 'Continuar',
  previous: 'Anterior',
  next: 'Siguiente',
  welcomeToUbuntuDescription:
    '<strong>Cargue sus credenciales de PJN</strong> para poder visualizar y gestionar todos sus expedientes. También puede agregar expedientes públicos para empezar a gestionarlos.',
  fileImportDescription:
    'Luego de cargar sus credenciales, recuerde importar los expedientes que le interese gestionar para poder ver sus detalles.<br />Este proceso puede tardar unos minutos.',
  addPublicFileDescription:
    'Si quiere visualizar expedientes públicos, presione el botón <strong>Agregar expediente público.</strong> Recuerde que también debe importarlos luego de agregarlos a la tabla. Este proceso puede tardar unos minutos.',
  workAsATeamDescription:
    'Ubuntulaw le da la posibilidad de tener su <strong>Espacio de trabajo</strong> y trabajar con colaboradores en sus tareas diarias. Puede ser invitado a un espacio o invitar otros usuarios a su propio espacio.'
});
