import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import UsersServices from '#services/Users/service';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'CREATE_USER',
      'UPDATE_USER_BY_ID',
      'DELETE_USER_BY_ID',
      'GET_USER_BY_ID',
      'GET_USERS',
      'SEND_WELCOME_EMAIL'
    ],
    []
  ),
  '@@USERS'
);

const UsersActions = {
  createUser: ({ user }) => ({
    type: actions.CREATE_USER,
    target: TARGETS.CREATE_USER,
    payload: user,
    service: UsersServices.createUser
  }),
  updateUserById: (id, updatedFields, onSuccess) => ({
    type: actions.UPDATE_USER,
    target: TARGETS.UPDATE_USER,
    payload: { id, ...updatedFields },
    service: UsersServices.updateUserById,
    injections: [withPostSuccess(onSuccess)]
  }),
  deleteUserById: id => ({
    type: actions.DELETE_USER_BY_ID,
    target: TARGETS.DELETE_USER,
    service: UsersServices.deleteUserById,
    payload: id
  }),
  getUserById: payload => ({
    payload,
    type: actions.GET_USER_BY_ID,
    target: TARGETS.SELECTED_USER,
    service: UsersServices.getUserById
  }),
  getUsers: query => ({
    type: actions.GET_USERS,
    target: TARGETS.SELECTED_USERS,
    service: UsersServices.getUsers,
    payload: query
  }),
  sendWelcomeEmail: () => ({
    type: actions.SEND_WELCOME_EMAIL,
    target: TARGETS.SEND_WELCOME_EMAIL,
    service: UsersServices.sendWelcomeEmail
  })
};

export default UsersActions;
