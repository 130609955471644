import i18next from 'i18next';

i18next.addResources('es', 'PART_OPTIONS', {
  plaintiff: 'Actora',
  defendant: 'Demandada',
  other: 'Otra'
});

i18next.addResources('es', 'FILE_STATE_OPTIONS', {
  start: '(I) Inicio',
  test: '(II) Prueba',
  allegations: '(III) Alegatos',
  judgment: '(IV) Sentencia',
  execution: '(V) Ejecución'
});

i18next.addResources('es', 'JURISDICTIONS', {
  CSJ: 'Corte Suprema de Justicia de la Nación',
  CIV: 'Cámara Nacional de Apelaciones en lo Civil',
  CAF: 'Cámara Nacional de Apelaciones en lo Contencioso Administrativo Federal',
  CCF: 'Cámara Nacional de Apelaciones en lo Civil y Comercial Federal',
  CNE: 'Cámara Nacional Electoral',
  CSS: 'Cámara Federal de la Seguridad Social',
  CPE: 'Cámara Nacional de Apelaciones en lo Penal Económico',
  CNT: 'Cámara Nacional de Apelaciones del Trabajo',
  CFP: 'Cámara Criminal y Correccional Federal',
  CCC: 'Cámara Nacional de Apelaciones en lo Criminal y Correccional',
  COM: 'Cámara Nacional de Apelaciones en lo Comercial',
  CPF: 'Cámara Federal de Casación Penal',
  CPN: 'Cámara Nacional Casación Penal',
  FBB: 'Justicia Federal de Bahía Blanca',
  FCR: 'Justicia Federal de Comodoro Rivadavia',
  FCB: 'Justicia Federal de Córdoba',
  FCT: 'Justicia Federal de Corrientes',
  FGR: 'Justicia Federal de General Roca',
  FLP: 'Justicia Federal de La Plata',
  FMP: 'Justicia Federal de Mar del Plata',
  FMZ: 'Justicia Federal de Mendoza',
  FPO: 'Justicia Federal de Posadas',
  FPA: 'Justicia Federal de Paraná',
  FRE: 'Justicia Federal de Resistencia',
  FSA: 'Justicia Federal de Salta',
  FRO: 'Justicia Federal de Rosario',
  FSM: 'Justicia Federal de San Martín',
  FTU: 'Justicia Federal de Tucumán'
});
