import { withPostSuccess } from 'redux-recompose';

import MixpanelService from '#services/MixpanelService';
import TasksActions from '#redux/Tasks/actions';
import { ASSIGNMENT_TYPE, MIXPANEL_EVENTS } from '#constants/mixpanel';

const filterAssignees = (assignees, oldAssigneesFromTask) =>
  assignees.filter(id => !oldAssigneesFromTask.some(oldAssignee => oldAssignee.id === id));

export const postCheckoutInjections = (data, currentUser, currentWorkspace, oldTask) => [
  withPostSuccess(dispatch => {
    dispatch(TasksActions.getTasks({ legalCaseId: data.legalCase }));
    dispatch(TasksActions.getWorkspaceTasks());
    MixpanelService.track(oldTask ? MIXPANEL_EVENTS.TASK_UPDATED : MIXPANEL_EVENTS.TASK_CREATED, {
      userId: currentUser?.id,
      userSurname: currentUser?.surname,
      userEmail: currentUser?.email,
      workspaceId: currentUser?.workspace,
      workspaceName: currentWorkspace?.name
    });

    if (data?.assignees) {
      const assignees = oldTask?.assignees
        ? filterAssignees(data.assignees, oldTask.assignees)
        : data.assignees;

      assignees.forEach(assigneeId => {
        MixpanelService.track(MIXPANEL_EVENTS.ASSIGNMENT, {
          userId: currentUser?.id,
          userSurname: currentUser?.surname,
          userEmail: currentUser?.email,
          workspaceId: currentUser?.workspace,
          workspaceName: currentWorkspace?.name,
          type: ASSIGNMENT_TYPE.TASK,
          assignedUserId: assigneeId
        });
      });
    }
  })
];
