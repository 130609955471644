import { createTypes, completeTypes, withPostFailure } from 'redux-recompose';
import withPostSuccess from 'redux-recompose/lib/injections/withPostSuccess';

import * as FileService from '../../services/FileService';

import { downloadFile, parseXMLNodeValue } from '#utils/file';
import ModalActions from '#redux/Modal/actions';
import AuthActions from '#redux/Auth/actions';
import { MODALS } from '#redux/Modal/constants';
import LegalCaseActions from '#redux/LegalCases/actions';
import { NODE_VALUES } from '#constants/s3';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes([], ['UPLOAD_IMAGE', 'DOWNLOAD_CSV', 'GET_IMAGE_URL']),
  '@@FILE'
);

export const actionCreators = {
  uploadImage: (file, onSuccess, updateUser) => ({
    type: actions.UPLOAD_IMAGE,
    target: TARGETS.UPLOAD_IMAGE,
    payload: { file },
    service: FileService.uploadFileToS3,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(ModalActions.closeModal(MODALS.IMAGE_CROPPER));
        if (updateUser) {
          dispatch(
            AuthActions.updateUser({
              user: { picture: parseXMLNodeValue(response.data, NODE_VALUES.LOCATION) },
              shouldRedirect: false
            })
          );
        }
        onSuccess?.(parseXMLNodeValue(response.data, NODE_VALUES.LOCATION));
      })
    ]
  }),
  getSignedUrl: data => ({
    type: actions.GET_IMAGE_URL,
    target: TARGETS.IMAGE_URL,
    service: FileService.getSignedUrl,
    payload: data
  }),
  uploadEvidencePDF: ({ file, getFieldToUpdate, evidenceId, evidenceType, id }) => ({
    type: actions.UPLOAD_IMAGE,
    target: TARGETS.UPLOAD_IMAGE,
    payload: { file, contentType: 'application/pdf' },
    service: FileService.uploadFileToS3,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          LegalCaseActions.updateEvidence(
            {
              id,
              evidenceId,
              evidenceType,
              evidence: getFieldToUpdate(parseXMLNodeValue(response.data, NODE_VALUES.LOCATION))
            },
            false
          )
        );
      })
    ]
  }),
  downloadCSV: (data, filename) => ({
    type: actions.DOWNLOAD_CSV,
    target: TARGETS.DOWNLOAD_CSV,
    service: FileService.downloadCsv(data, filename),
    injections: [
      withPostSuccess((_, response) => {
        if (response.status === 200) {
          downloadFile(response.data, filename);
        }
      })
    ]
  }),
  uploadFile: ({ file, onSuccess, onFailure }) => ({
    type: actions.UPLOAD_IMAGE,
    target: TARGETS.UPLOAD_IMAGE,
    payload: { file },
    service: FileService.uploadFileToS3,
    injections: [
      withPostSuccess((_, response) => {
        onSuccess?.(parseXMLNodeValue(response?.data, NODE_VALUES.LOCATION));
      }),
      withPostFailure((_, response) => {
        onFailure?.(response?.data);
      })
    ]
  })
};

export default actionCreators;
