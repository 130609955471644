import { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { pathMatches } from '#utils/paths';
import { useHover } from '#hooks/useHover';

const useMenuItem = ({ item, onClick }) => {
  const location = useLocation();
  const [isHovering, setHover] = useHover();

  const subMenuSelected = item.subMenus?.some(subItem => pathMatches(subItem, location));
  const [subMenuOpen, setSubMenuOpen] = useState(subMenuSelected);

  const openSubMenu = () => setSubMenuOpen(!subMenuOpen);
  const handleClick = () => {
    onClick?.(item);
    setSubMenuOpen(!subMenuOpen);
  };

  const isSelected = useRouteMatch(item.route?.path);
  const ImgComponent = item?.icon;
  const ItemComponent = item?.subMenus?.length || !!item?.onClick ? 'button' : Link;

  const isSelectedOrHovering = isSelected || isHovering;

  return {
    setHover,
    ImgComponent,
    ItemComponent,
    isSelectedOrHovering,
    openSubMenu,
    handleClick,
    isSelected,
    isHovering,
    subMenuOpen,
    RightButtonIcon: item?.rightButton?.icon
  };
};

export default useMenuItem;
