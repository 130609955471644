import i18next from 'i18next';

i18next.addResources('es', 'LastUpdate', {
  lastUpdate: 'Última actualización:'
});

i18next.addResources('es', 'MenuItems', {
  casesList: 'Lista de causas',
  notifications: 'Notificaciones',
  configurations: 'Configuraciones',
  calendar: 'Agenda',
  profile: 'Mi Perfil',
  credentials: 'Mis Credenciales',
  workspace: 'Espacio de trabajo',
  electronicCards: 'Cédulas electrónicas'
});
