import i18next from 'i18next';

i18next.addResources('es', 'WorkspaceCreationRedirect', {
  success: 'Su espacio de trabajo fue creado con éxito',
  successMessage:
    'A continuación lo redirigiremos a Ubuntulaw, para que pueda comenzar a trabajar con su equipo.',
  redirect: 'Ir a Ubuntulaw',
  enjoy: 'Disfrute se su espacio de trabajo con el beneficio de una',
  freeTrial: 'prueba gratuita de 30 días.'
});
