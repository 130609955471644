import i18next from 'i18next';

import { ROLES } from '#constants/users';
import { INVITATION_STATUS } from '#models/workspace';

const { ADMIN, OWNER, LAWYER } = ROLES;

i18next.addResources('es', 'Workspace', {
  title: 'ESPACIO DE TRABAJO',
  description: 'Su rol asignado es',
  [`role${ADMIN}`]: 'Administrador',
  [`role${OWNER}`]: 'Dueño',
  [`role${LAWYER}`]: 'Colaborador',
  configureWorkspace: 'Configurar espacio de trabajo',
  abandonWorkspace: 'Abandonar espacio de trabajo',
  inviteUsers: 'Invitar usuarios por mail',
  members: 'Miembros',
  credentials: 'Credenciales',
  searchBar: 'Buscar usuarios por nombres',
  yourName: '{{name}} (Usted)',
  role: 'Rol',
  name: 'Nombre completo',
  email: 'Correo electrónico',
  connection: 'Última conexión',
  edit: 'Editar',
  delete: 'Eliminar',
  credential: 'Credencial',
  noMembers: 'Aún no hay otros usuarios en su espacio de trabajo.',
  inviteToStart: 'Invite usuarios a sumarse para comenzar a trabajar en equipo.',
  [`invitation${INVITATION_STATUS.PENDING}`]: 'Invitación pendiente',
  [`invitation${INVITATION_STATUS.EXPIRED}`]: 'Invitación expirada',
  pending: 'pendiente',
  send: 'Reenviar',
  [`tooltip${OWNER}`]:
    ' Tiene acceso total a todos los expedientes del espacio, es el encargado de gestionar la suscripción del espacio, puede gestionar usuarios y eliminar el espacio.',
  [`tooltip${ADMIN}`]: 'Tiene acceso total a todos los expedientes del espacio y puede gestionar usuarios.',
  [`tooltip${LAWYER}`]:
    'Sólo tiene acceso a aquellos expedientes a los cuales fue asignado, y a aquellos que estén vinculados a sus credenciales propias. No puede gestionar usuarios.',
  successResendToast: 'Invitación reenviada exitosamente'
});
