import immutable from 'seamless-immutable';
import { createReducer, onReadValue, onSetValue } from 'redux-recompose';

import { actions } from './actions';

const reducerDescription = {
  [actions.OPEN_MODAL]: onSetValue(true),
  [actions.CLOSE_MODAL]: onSetValue(false),
  [actions.CLEAR_MODALS]: () => immutable({}),
  [actions.SET_MODAL_DATA]: onReadValue()
};

const reducer = createReducer(immutable({}), reducerDescription);

export default reducer;
