import { SECONDS } from './time';

export const SUBSCRIPTION_VARIANTS = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual'
};

export const SUBSCRIPTION_STATUS = {
  PENDING: 'pending',
  AUTHORIZED: 'authorized',
  PAUSED: 'paused',
  CANCELLED: 'cancelled'
};

export const SUBSCRIPTION_PLANS = {
  BASIC: 'basic',
  STANDARD: 'standard',
  PREMIUM: 'premium'
};

export const WORKSPACE_STATUS = {
  NORMAL: 'normal',
  FREE_TRIAL: 'freeTrial',
  FREE_TRIAL_ENDING: 'freeTrialEnding',
  FREE_TRIAL_OVER: 'freeTrialOver',
  SUBSCRIPTION_CANCELLED: 'subscriptionCancelled',
  SUBSCRIPTION_PAUSED: 'subscriptionPaused',
  SUBSCRIPTION_OVER: 'subscriptionOver'
};

export const DISABLED_WORKSPACE_STATUSES = [
  WORKSPACE_STATUS.SUBSCRIPTION_PAUSED,
  WORKSPACE_STATUS.SUBSCRIPTION_OVER,
  WORKSPACE_STATUS.FREE_TRIAL_OVER
];

export const PAID_SUBSCRIPTION_STATUSES = [WORKSPACE_STATUS.NORMAL, WORKSPACE_STATUS.SUBSCRIPTION_PAUSED];

export const FREE_TRIAL_STATUSES = [
  WORKSPACE_STATUS.FREE_TRIAL,
  WORKSPACE_STATUS.FREE_TRIAL_ENDING,
  WORKSPACE_STATUS.FREE_TRIAL_OVER
];

export const TOTAL_FILES = {
  [SUBSCRIPTION_PLANS.BASIC]: 100,
  [SUBSCRIPTION_PLANS.STANDARD]: 250,
  [SUBSCRIPTION_PLANS.PREMIUM]: 500
};

export const CREATE_SUBSCRIPTION_DELAY = 2 * SECONDS;
