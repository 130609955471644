import { arrayOf, bool, elementType, shape, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Helmet from '#components/Helmet';
import { getCurrentRouteRedirect } from '#models/routes';
import LocalStorage from '#services/LocalStorageService';

const AuthenticatedRoute = ({ title, description, path, component: Component, componentProps, ...props }) => {
  const tokenManager = LocalStorage.getTokenManager();
  const { currentUser, currentUserLoading } = useSelector(state => state.auth);
  const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
  const { pathname, search } = useLocation();
  const routeToRedirect = useMemo(
    () =>
      getCurrentRouteRedirect({
        pathname,
        search,
        tokenManager,
        currentUser,
        currentWorkspace
      }),
    [tokenManager, pathname, currentUserLoading]
  );

  return routeToRedirect ? (
    <Redirect to={routeToRedirect} />
  ) : (
    <>
      <Helmet title={title} description={description} />
      <Route path={path} render={() => <Component {...componentProps} />} {...props} />
    </>
  );
};

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
  component: elementType,
  componentProps: shape({}),
  description: string,
  publicRoute: bool,
  supportedRoles: arrayOf(string),
  title: string
};

export default withRouter(AuthenticatedRoute);
