import i18next from 'i18next';

i18next.addResources('es', 'PaymentMethod', {
  amex: 'American Express',
  debcabal: 'Cabal Débito',
  tarshop: 'Tarjeta Shopping',
  argencard: 'Argencard',
  debmaster: 'Mastercard Débito',
  naranja: 'Naranja',
  diners: 'Diners',
  cmr: 'CMR',
  cencosud: 'Cencosud',
  maestro: 'Maestro',
  debvisa: 'Visa Débito',
  visa: 'Visa',
  master: 'Mastercard',
  cabal: 'Cabal',
  pagofacil: 'Pago Fácil',
  rapipago: 'Rapipago',
  cardEndingWith: '{{card}} terminada en {{cardLastFourDigits}}'
});
