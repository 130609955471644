import i18next from 'i18next';

i18next.addResourceBundle('es', 'ActionsNotifications', {
  allFiles: {
    title: 'Todos los expedientes',
    description: 'Se notificarán las actuaciones de todos los expedientes'
  },
  favouriteFiles: {
    title: 'Expedientes favoritos',
    description: 'Se notificarán las actuaciones de los expedientes favoritos'
  }
});

i18next.addResourceBundle('es', 'ActionNotificationsOptions', {
  writed: {
    label: 'Escrito agregado / incorporado',
    tooltipMessage: 'Se notificarán todos los escritos presentados por todas las partes intervinientes.'
  },
  office: {
    label: 'Despachos',
    profileLabel: 'Firma despacho',
    tooltipMessage:
      'Se notificarán todos los proveídos emitidos por el juzgado: autos de mero trámite, sentencia, etc.'
  },
  changeState: {
    label: 'Cambios de estado',
    profileLabel: 'Movimiento',
    tooltipMessage:
      'Se notificarán los cambios de estado del expediente: en letra, en despacho, en giro, etc.'
  },
  others: {
    label: 'Otros',
    tooltipMessage: 'Actuaciones no incluidas en las especificadas'
  }
});
