import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import TasksActions from '#redux/Tasks/actions';
import { getRequestParams } from '#components/EvidenceTables/utils';
import {
  getLegalCaseById,
  getLegalCases,
  importLegalCase,
  importLegalCases,
  updateLegalCase,
  getLegalCaseListField,
  updateLegalCaseListField,
  uploadEvidence,
  deleteEvidence,
  updateEvidence,
  updateAction,
  searchPublicFile,
  addPublicFile,
  leaveNote
} from '#services/LegalCaseService';
import FileActions from '#redux/File/actions';
import { LEGAL_CASE_QUERY } from '#constants/legalCases';
import MixpanelService from '#services/MixpanelService';
import { MIXPANEL_EVENTS } from '#constants/mixpanel';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'GET_CASES',
      'GET_IMPORTED_CASES',
      'IMPORT_CASES',
      'IMPORT_CASE',
      'GET_CASE_BY_ID',
      'IMPORT_ACTIONS',
      'IMPORT_PARTICIPANTS',
      'IMPORT_EXPERTS',
      'IMPORT_PROSECUTORS',
      'IMPORT_INVOLVED_FILES',
      'IMPORT_LEGAL_CASE_LIST_FIELD',
      'UPDATE_LEGAL_CASE_LIST_FIELD',
      'UPDATE_LEGAL_CASE',
      'IMPORT_ACTIONS_PDFS',
      'IMPORT_EVIDENCE',
      'UPLOAD_EVIDENCE',
      'DELETE_EVIDENCE',
      'UPDATE_EVIDENCE',
      'UPDATE_LEGAL_ACTION',
      'SEARCH_PUBLIC_CASE',
      'ADD_PUBLIC_CASE',
      'LEAVE_NOTE'
    ],
    [
      'RESET_IMPORT_CASE_STATE',
      'SET_EVIDENCE_DATA',
      'UPDATE_SELECTED_CASE',
      'UPDATE_INVOLVED_FILE',
      'SET_ASSIGNEES_ERROR'
    ]
  ),
  '@@CASES'
);

const actionCreators = {
  getCases: (data, onSuccess) => ({
    type: actions.GET_CASES,
    target: TARGETS.SELECTED_CASES,
    service: getLegalCases,
    payload: data,
    injections: [withPostSuccess(onSuccess)]
  }),
  getImportedCases: onSuccess => ({
    type: actions.GET_IMPORTED_CASES,
    target: TARGETS.IMPORTED_CASES,
    service: getLegalCases,
    payload: LEGAL_CASE_QUERY,
    injections: [withPostSuccess(onSuccess)]
  }),
  updateSelectedCase: data => ({
    type: actions.UPDATE_SELECTED_CASE,
    target: TARGETS.SELECTED_CASES,
    payload: data
  }),
  updateInvolvedFile: data => ({
    type: actions.UPDATE_INVOLVED_FILE,
    target: TARGETS.IMPORT_INVOLVED_FILES,
    payload: data
  }),
  getCaseById: id => ({
    type: actions.GET_CASE_BY_ID,
    target: TARGETS.CURRENT_CASE,
    service: getLegalCaseById,
    payload: id,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(TasksActions.getTasks({ legalCaseId: id }));
      })
    ]
  }),
  importCases: data => ({
    type: actions.IMPORT_CASES,
    target: TARGETS.IMPORT_CASES,
    service: importLegalCases,
    payload: data
  }),
  importCase: (data, onFailure) => ({
    type: actions.IMPORT_CASE,
    target: TARGETS.IMPORT_CASE,
    service: importLegalCase,
    payload: data,
    injections: [withPostFailure((_, response) => onFailure?.(response.data[0]?.internal_code))]
  }),
  resetImportCaseState: () => ({
    type: actions.RESET_IMPORT_CASE_STATE,
    payload: {
      importCase: null,
      importCaseError: null,
      importCaseLoading: null
    }
  }),
  getLegalCaseListField: ({ listFieldType, ...data }) => ({
    type: actions.IMPORT_LEGAL_CASE_LIST_FIELD,
    target: TARGETS[`IMPORT_${listFieldType?.toUpperCase()}`],
    service: getLegalCaseListField,
    payload: { listFieldType, ...data }
  }),
  getEvidenceFiles: ({ evidenceType, ...data }) => ({
    type: actions.IMPORT_EVIDENCE,
    target: TARGETS[`IMPORT_${evidenceType?.toUpperCase()}_EVIDENCE`],
    service: getLegalCaseListField,
    payload: data
  }),
  getEvidenceFilesByPart: (data, onSuccess) => ({
    type: actions.IMPORT_EVIDENCE,
    target: TARGETS.EVIDENCE_BY_PART,
    service: getLegalCaseListField,
    payload: data,
    injections: [withPostSuccess(onSuccess)]
  }),
  updateLegalCaseListField: ({ listFieldType, legalCaseId, ...data }) => ({
    type: actions.UPDATE_LEGAL_CASE_LIST_FIELD,
    target: TARGETS[`UPDATE_${listFieldType?.toUpperCase()}`],
    service: updateLegalCaseListField,
    payload: { listFieldType, legalCaseId, ...data },
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.getLegalCaseListField({ listFieldType, id: legalCaseId }));
      })
    ]
  }),
  getInvolvedFiles: data => ({
    type: actions.IMPORT_INVOLVED_FILES,
    target: TARGETS.IMPORT_INVOLVED_FILES,
    service: getLegalCaseListField,
    payload: data
  }),
  leaveNote: data => ({
    type: actions.LEAVE_NOTE,
    target: TARGETS.LEAVE_NOTE,
    service: leaveNote,
    payload: data
  }),
  updateLegalCase: (data, getFiles) => ({
    type: actions.UPDATE_LEGAL_CASE,
    target: TARGETS.UPDATE_LEGAL_CASE,
    service: updateLegalCase,
    payload: data,
    injections: [
      withPostSuccess(() => {
        getFiles?.();
      })
    ]
  }),
  updateEvidence: ({ id, evidenceType, evidence, evidenceId, pdfsToUpload = [] }) => ({
    type: actions.UPDATE_EVIDENCE,
    target: TARGETS.UPDATE_EVIDENCE,
    service: updateEvidence,
    payload: { id, evidenceId, evidence },
    injections: [
      withPostSuccess(dispatch => {
        pdfsToUpload.forEach(pdfToUpload => {
          dispatch(
            FileActions.uploadEvidencePDF({
              ...pdfToUpload,
              evidenceId,
              id,
              evidenceType
            })
          );
        });
        dispatch(actionCreators.getEvidenceFiles(getRequestParams(id, evidenceType)));
      })
    ]
  }),
  setEvidenceData: data => ({
    type: actions.SET_EVIDENCE_DATA,
    target: TARGETS.EVIDENCE_DATA,
    payload: data
  }),
  uploadEvidence: ({ id, evidenceType, pdfsToUpload = [], evidence }, currentUser, currentWorkspace) => ({
    type: actions.UPLOAD_EVIDENCE,
    target: TARGETS.UPLOAD_EVIDENCE,
    service: uploadEvidence,
    payload: { id, evidence },
    injections: [
      withPostSuccess((dispatch, response) => {
        pdfsToUpload.forEach(pdfToUpload => {
          dispatch(
            FileActions.uploadEvidencePDF({
              ...pdfToUpload,
              id,
              evidenceId: response?.data?.id,
              evidenceType
            })
          );
        });
        MixpanelService.track(MIXPANEL_EVENTS.EVIDENCE_UPLOADED, {
          userId: currentUser?.id,
          userSurname: currentUser?.surname,
          userEmail: currentUser?.email,
          workspaceId: currentUser?.workspace,
          workspaceName: currentWorkspace?.name
        });
        dispatch(actionCreators.getEvidenceFiles(getRequestParams(id, evidenceType)));
      })
    ]
  }),
  deleteEvidence: ({ getFiles, ...data }) => ({
    type: actions.DELETE_EVIDENCE,
    target: TARGETS.DELETE_EVIDENCE,
    service: deleteEvidence,
    payload: data,
    injections: [withPostSuccess(getFiles)]
  }),
  updateLegalAction: ({ getFiles, ...data }) => ({
    type: actions.UPDATE_LEGAL_ACTION,
    target: TARGETS.UPDATE_LEGAL_ACTION,
    service: updateAction,
    payload: data,
    injections: [withPostSuccess(getFiles)]
  }),
  searchPublicCase: data => ({
    type: actions.SEARCH_PUBLIC_CASE,
    target: TARGETS.SEARCH_CASE,
    service: searchPublicFile,
    payload: data
  }),
  addPublicCase: (id, onSuccess) => ({
    type: actions.ADD_PUBLIC_CASE,
    target: TARGETS.ADD_CASE,
    service: addPublicFile,
    payload: id,
    injections: [withPostSuccess(onSuccess)]
  }),
  setAssigneesError: payload => ({
    type: actions.SET_ASSIGNEES_ERROR,
    target: TARGETS.ASSIGNEES_ERROR,
    payload
  })
};

export default actionCreators;
