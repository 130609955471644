import i18next from 'i18next';

i18next.addResources('es', 'PJNImportFileModal', {
  import: 'Importar',
  cancel: 'Cancelar',
  importMessage: 'Estamos por importar el detalle del expediente seleccionado',
  loadingMessage: 'Importando el detalle del expediente',
  errorMessage: 'No pudimos importar el detalle del expediente, por favor vuelva a intentarlo.',
  successMessage:
    'El detalle del expediente se importó con éxito, puede verlo haciendo click en la fila del expediente.'
});
