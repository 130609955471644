import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';
import { TARGETS } from './constants';

const initialState = {
  [TARGETS.IMPORT_EVENTS]: [],
  [TARGETS.GET_EVENTS]: [],
  [TARGETS.UPLOAD_EVENT]: null,
  [TARGETS.DELETE_EVENT]: null,
  [TARGETS.EDIT_EVENT]: null
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [
    actions.IMPORT_EVENTS,
    actions.GET_EVENTS,
    actions.UPLOAD_EVENT,
    actions.DELETE_EVENT,
    actions.EDIT_EVENT
  ]
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
