import { useDispatch, useSelector } from 'react-redux';
import { merge } from '@Zerf-dev/utils';

import { ANIMATION_DURATION } from '#components/PJNTable/constants';
import modalActions from '#redux/Modal/actions';

export const useModal = modal => {
  const dispatch = useDispatch();

  const modalData = useSelector(state => state.modal[`${modal}Data`] || {});
  const isOpen = useSelector(state => state.modal[modal]);

  const setModalData = data => dispatch(modalActions.setModalData(modal, data));

  const addModalData = aditionalData => dispatch(setModalData(merge([modalData, aditionalData])));

  const openModal = () => dispatch(modalActions.openModal(modal));

  const openModalWithData = data => {
    setModalData(data);
    openModal();
  };

  const closeModal = () => {
    dispatch(modalActions.closeModal(modal));
    setTimeout(() => setModalData({}), ANIMATION_DURATION);
  };

  return { isOpen, openModal, openModalWithData, closeModal, setModalData, modalData, addModalData };
};
