import cn from 'classnames';

import { ReactComponent as Logo } from '#assets/logo.svg';
import { ReactComponent as Menu } from '#assets/menu.svg';
import { useModal } from '#hooks/useModal';
import { MODALS } from '#redux/Modal/constants';

import styles from './styles.module.scss';

function MenuHeader() {
  const { isOpen, closeModal, openModal } = useModal(MODALS.SIDE_BAR);

  return (
    <div className={styles.menuHeader}>
      <div className={styles.menuIconContainer}>
        <Menu className={styles.icon} onClick={() => (isOpen ? closeModal() : openModal())} />
      </div>
      <div className={cn(styles.logoContainer, { [styles.logoContainerClose]: !isOpen })}>
        <Logo className={styles.logo} />
      </div>
    </div>
  );
}

export default MenuHeader;
