import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue, onSetValue } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  onInitialLoad: true,
  signIn: null,
  checkEmail: null,
  currentUser: null,
  signInParams: null,
  updateUser: null,
  createCredential: null,
  errorCreatingCredential: null,
  currentCredentials: [],
  deleteCredential: null
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [
    actions.SIGN_IN,
    actions.GET_CURRENT_USER,
    actions.CHECK_EMAIL,
    actions.UPDATE_USER,
    actions.CREATE_CREDENTIAL,
    actions.GET_CREDENTIALS,
    actions.DELETE_CREDENTIAL
  ],
  override: {
    [actions.SIGN_IN_PARAMS]: onReadValue(),
    [actions.LOGOUT]: onSetValue(null),
    [actions.SET_CREATE_CREDENTIAL_ERROR]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
