import i18next from 'i18next';

i18next.addResources('es', 'ConfigurationsMainTitles', {
  notificationsEmails: 'Emails de notificaciones',
  cardsNotifications: 'Cédulas electrónicas',
  actionsNotifications: 'Actuaciones',
  leaveNoteNotificationIsActive: 'Notas'
});

i18next.addResourceBundle('es', 'ConfigurationsSection', {
  notificationsEmails: {
    title: 'Recibir notificaciones',
    description: 'Se notificarán las actualizaciones de los expedientes en los emails seleccionados.'
  }
});
