import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';

import * as EventService from '#services/EventService';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(['IMPORT_EVENTS', 'GET_EVENTS', 'UPLOAD_EVENT', 'DELETE_EVENT', 'EDIT_EVENT']),
  '@@EVENTS'
);

export const actionCreator = {
  importEvent: (data = {}) => ({
    type: actions.IMPORT_EVENTS,
    target: TARGETS.IMPORT_EVENTS,
    service: EventService.getEvents,
    payload: data
  }),
  getEvents: (data = {}) => ({
    type: actions.GET_EVENTS,
    target: TARGETS.GET_EVENTS,
    service: EventService.getEvents,
    payload: data
  }),
  uploadEvent: data => ({
    type: actions.UPLOAD_EVENT,
    target: TARGETS.UPLOAD_EVENT,
    service: EventService.postEvent,
    payload: data,
    injections: [withPostSuccess(dispatch => dispatch(actionCreator.importEvent()))]
  }),
  deleteEvent: data => ({
    type: actions.DELETE_EVENT,
    target: TARGETS.DELETE_EVENT,
    service: EventService.deleteEvent,
    payload: data,
    injections: [withPostSuccess(dispatch => dispatch(actionCreator.importEvent()))]
  }),
  editEvent: data => ({
    type: actions.EDIT_EVENT,
    target: TARGETS.EDIT_EVENT,
    service: EventService.editEvent,
    payload: { ...data },
    injections: [withPostSuccess(dispatch => dispatch(actionCreator.importEvent()))]
  })
};

export default actionCreator;
