import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useLogout } from '#hooks/useLogout';
import { history } from '#redux/store';
import authActions from '#redux/Auth/actions';
import api, { apiSetup } from '#config/api';

import Routes from './components/ConnectedRoutes';

const AppRoutesContainer = () => {
  const dispatch = useDispatch();
  const handleLogout = useLogout();

  useEffect(() => {
    dispatch(authActions.init());
    apiSetup(api, handleLogout, dispatch);
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default AppRoutesContainer;
