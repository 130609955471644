import { func, node, oneOf, shape, string } from 'prop-types';

export const buttonTypePropType = oneOf(['button', 'submit', 'reset']);

export const buttonPropTypes = shape({
  buttonChildren: node,
  className: string,
  key: string,
  type: string,
  onClick: func
});
