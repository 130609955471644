import { bool } from 'prop-types';
import { useSelector } from 'react-redux';

import MenuItem from '../MenuItem';
import styles from '../styles.module.scss';

import { getWorkspaceItem } from './utils';

function WorkspaceItem({ isOpen }) {
  const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
  return (
    <div className="mt-2 flex w-full flex-col justify-center">
      <MenuItem
        item={getWorkspaceItem(currentWorkspace)}
        iconContainerClassName={styles.bottomContainer}
        itemClassName={styles.profileContainer}
        menuItemNotSelected={styles.bottomItemNotSelected}
        menuItemCloseSelected={styles.workspaceItemCloseSelected}
        menuItemSelected={styles.workspaceItemSelected}
        menuItemHovered={styles.workspaceItemHovered}
        isOpen={isOpen}
      />
    </div>
  );
}

WorkspaceItem.propTypes = {
  isOpen: bool
};

export default WorkspaceItem;
