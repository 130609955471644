import qs from 'qs';

import { mapAllObjectValues } from './object';
import { isEmpty } from './array';

export const getQueryParams = queryString => qs.parse(queryString, { ignoreQueryPrefix: true });

const mapEmptyArray = maybeArray =>
  Array.isArray(maybeArray) && isEmpty(maybeArray) ? ['000000000000000000000000'] : maybeArray;

export const getQueryString = (queryObject = {}) =>
  qs.stringify(mapAllObjectValues(queryObject, mapEmptyArray), {
    addQueryPrefix: true
  });

export const stripTrailingSlash = str => (str.endsWith('/') ? str.slice(0, -1) : str);

export const replaceHttpWithHttps = url =>
  url.includes('http://') ? url.replace('http://', 'https://') : url;
