import i18next from 'i18next';

i18next.addResources('es', 'EvidenceTables', {
  groupName: 'Tipo de prueba',
  expert: 'Pericial',
  informative: 'Informativa',
  documentary: 'Documental',
  testimony: 'Testimonial',
  other: 'Otros'
});

i18next.addResources('es', 'WitnessessDataModal', {
  title: 'Datos de testigo/s',
  section: 'Testigo {{number}}',
  nameAndSurname: 'Nombre y Apellido:',
  address: 'Dirección:',
  office: 'Oficina de notificaciones a cargo:',
  diligenceStatus: 'Estado de diligenciamiento:',
  part: 'Parte:'
});

i18next.addResources('es', 'DetailDataModal', {
  title: 'Detalle'
});

i18next.addResources('es', 'ExpertDataModal', {
  title: 'Datos del perito',
  nameAndSurname: 'Nombre y apellido:',
  email: 'Email:',
  contactNumber: 'Número de contacto:',
  freeField: 'Campo libre:'
});

i18next.addResources('es', 'PointsDataModal', {
  title: 'Puntos periciales'
});

i18next.addResources('es', 'OrderDataModal', {
  title: 'Pedidos'
});

i18next.addResources('es', 'CallNumber', {
  groupName: 'Número de llamado',
  firstCall: '1er Llamado',
  secondCall: '2do Llamado'
});

i18next.addResources('es', 'DiligenceStatus', {
  diligenced: 'Diligenciada',
  notified: 'Notificada',
  notNotified: 'Sin notificar'
});
