import i18next from 'i18next';

import { ROUTES } from '#constants/routes';
import { ReactComponent as LogoutIcon } from '#assets/logout.svg';
import avatarIcon from '#assets/avatar.svg';
import { ReactComponent as Credentials } from '#assets/credentials.svg';
import { ReactComponent as Scale } from '#assets/scale.svg';
import { ReactComponent as Calendar } from '#assets/calendar.svg';
import { ReactComponent as ElectronicCard } from '#assets/electronicCard.svg';
import { getCurrentRoute } from '#utils/routes';
import MixpanelService from '#services/MixpanelService';
import { MIXPANEL_EVENTS } from '#constants/mixpanel';

import styles from './components/styles.module.scss';
import Profile from './components/Profile';

export const topMenuItems = (currentUser, currentWorkspace) => [
  {
    name: i18next.t('MenuItems:casesList'),
    icon: Scale,
    route: {
      path: ROUTES.HOME.path
    }
  },
  {
    name: i18next.t('MenuItems:calendar'),
    icon: Calendar,
    onClickIcon: () => {
      const { path } = getCurrentRoute();
      if (path !== ROUTES.SCHEDULE.path) {
        MixpanelService.track(MIXPANEL_EVENTS.CALENDAR_OPENED, {
          userId: currentUser?.id,
          userSurname: currentUser?.surname,
          userEmail: currentUser?.email,
          workspaceId: currentUser?.workspace,
          workspaceName: currentWorkspace?.name
        });
      }
    },
    route: {
      path: ROUTES.SCHEDULE.path
    }
  },
  {
    name: i18next.t('MenuItems:electronicCards'),
    icon: ElectronicCard,
    route: {
      path: ROUTES.ELECTRONIC_CARDS.path
    }
  }
];
export const bottomMenuItems = (currentUser, handleLogout) => [
  {
    item: {
      name: i18next.t('MenuItems:credentials'),
      icon: Credentials,
      route: {
        path: ROUTES.CREDENTIALS.path
      }
    }
  },
  {
    item: {
      name: i18next.t('MenuItems:profile'),
      icon: () => <img src={currentUser?.picture || avatarIcon} className="w-10 max-w-none rounded-3xl" />,
      route: {
        path: ROUTES.PROFILE.path
      },
      titleComponent: Profile,
      rightButton: {
        icon: () => <LogoutIcon className="h-6 w-6" />,
        onClick: handleLogout
      }
    },
    itemClassName: styles.profileContainer,
    iconContainerClassName: styles.bottomContainer,
    menuItemNotSelected: styles.bottomItemNotSelected,
    menuItemCloseSelected: styles.profileItemCloseSelected,
    menuItemSelected: styles.profileItemSelected,
    menuItemHovered: styles.profileItemHovered
  }
];
