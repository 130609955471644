import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  selectedTasks: [],
  createTask: null,
  updateTask: null,
  deleteTask: null,
  workspaceTasks: []
});

const reducerDescription = {
  primaryActions: [
    actions.GET_TASKS,
    actions.CREATE_TASK,
    actions.UPDATE_TASK,
    actions.DELETE_TASK,
    actions.GET_WORKSPACE_TASKS
  ]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
