import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  mainTitle: 'Comience a seguir sus casos, desde cualquier lugar y de una forma más rápida y simple',
  formTitle: 'Registrate o inicia sesión:',
  email: 'Email',
  banner: 'Gestión para profesionales de la abogacía',
  emailPlaceholder: 'Ingrese su Email',
  signIn: 'Ingresar',
  or: 'o',
  signInWithGoogle: 'Ingresar con Google',
  signInWithMicrosoft: 'Ingresar con Microsoft',
  requiredEmail: 'El email es requrido'
});
