import { addMinutes, dateToTime, formatTime, isSameDate } from '@Zerf-dev/utils';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_LOCALE } from '#constants/locales';

import { GET_DAY, GET_MONTH, MAX_MINUTES, ROUNDED_MINUTES } from './constants';

dayjs.extend(utc);

export const isDate = date => date instanceof Date;

// Getters
export const currentDate = () => new Date();

export const getUniqueDates = dates =>
  dates.filter((date, index, self) => self.findIndex(compareDate => isSameDate(date, compareDate)) === index);

// Formatting

// DD/MM/YYYY - HH:MM
export const buildDateAndHour = (date, locale = DEFAULT_LOCALE) =>
  `${date.toLocaleDateString(locale)} - ${dateToTime(date)}hs`;

// DD/MM/YYYY
export const buildDateDDMMYYY = date =>
  `${formatTime(date?.getDate?.())}/${formatTime(date?.getMonth?.() + 1)}/${date?.getFullYear?.()}`;

// DD MM
export const buildDateDDMM = (date, separator) =>
  `${formatTime(date?.getDate())} ${separator || ''} ${GET_MONTH.ES[date?.getMonth()]}`;

// DD, dd MM
export const buildDateDDddMM = date => `${GET_DAY.ES[date?.getDay()]}, ${buildDateDDMM(date)}`;

// HH:MM - HH:MMhs
export const buildHourRange = (from, to) => `${dateToTime(new Date(from))} - ${dateToTime(new Date(to))}hs`;

// Setters
export const addMonths = (date, months) => {
  const validDate = new Date(date);
  validDate.setMonth(validDate.getMonth() + months);
  return validDate;
};

// Validations

export const isInvalidDate = (date, invalidDays) => invalidDays.includes(new Date(date).getDay());

export const isSameDateAndTime = (date, compareDate) =>
  date.getTime() === compareDate.getTime() && isSameDate(date, compareDate);

// Ranges

export const isFromToday = date => date === new Date().getDate().toString();

export const roundHour = date => {
  const minutes = date.getMinutes();
  const minutesToAdd = minutes % ROUNDED_MINUTES === 0 ? 0 : ROUNDED_MINUTES - (minutes % ROUNDED_MINUTES);
  return addMinutes(date, minutesToAdd);
};

export const minutesToTime = minutes =>
  minutes < MAX_MINUTES
    ? `${minutes} min`
    : `${Math.floor(minutes / MAX_MINUTES)}:${formatTime(minutes % MAX_MINUTES)} hs`;

export const modifyTime = (date, { hour, minute }) => {
  const dateToModify = new Date(date);
  dateToModify.setHours(hour);
  dateToModify.setMinutes(minute);
  return dateToModify;
};

export const mergeDates = (date, time) =>
  modifyTime(date, { hour: time.getHours(), minute: time.getMinutes() });

export const mergeObjectDates = ({ date, time }) => mergeDates(date, time);

export const convertToDate = string => new Date(string);

// Convert string DD/MM/YYYY - HH:MMhs to Date
export const convertStringToDate = string => {
  const [date, time] = string.split(' - ');
  const formattedTime = time.replace('hs', '');
  const [day, month, year] = date.split('/');
  const [hour, minute] = formattedTime.split(':');
  return new Date(year, month - 1, day, hour, minute);
};

// dayjs extensions

export const getMonthName = (date, locale = DEFAULT_LOCALE) => date.locale(locale).format('MMM');

export const getFullMonthName = (date, locale = DEFAULT_LOCALE) =>
  GET_MONTH[locale.toUpperCase()][date?.month?.() || 0];

export const getMonths = (year = dayjs().year()) => {
  const startOfYear = dayjs().startOf('year').year(year);
  const months = Array.from({ length: 12 }, (_, index) => {
    const month = startOfYear.add(index, 'month');
    return { label: getMonthName(month), value: month, disabled: month.isBefore(dayjs(), 'month') };
  });
  return months;
};

export const getCurrentYear = () => currentDate().getFullYear();

export const getYear = date => date?.year?.() || getCurrentYear();

export const startOfDay = date => dayjs(date).startOf('day').toDate();

export const startOfDayUTC = date => dayjs(date).utcOffset(0).startOf('day').toDate();

export const endOfDay = date => dayjs(date).endOf('day').toDate();

export const endOfDayUTC = date => dayjs(date).utcOffset(0).endOf('day').toDate();

export const startOfMonth = date => dayjs(date).startOf('month').toDate();

export const endOfMonth = date => dayjs(date).endOf('month').toDate();

export const formatDateESDayJs = date => dayjs(date).locale('es').format('DD [de] MMMM [de] YYYY');

export const formatDateESDayJsWithoutYear = date => dayjs(date).locale('es').format('DD [de] MMMM');

// DD/MM/YYYY HH:MM
export const buildDateAndTime = (date, locale = DEFAULT_LOCALE) =>
  `${date.toLocaleDateString(locale)} ${dateToTime(date)}hs`;

export const parseDate = date => ({ hour: date?.getHours(), minute: date?.getMinutes() });

export const mapDate = date => (date.includes('Z') ? buildDateDDMMYYY(new Date(date)) : date);

export const isStartDateGreater = (startDate, endDate) =>
  startDate.date > endDate.date || (startDate.date >= endDate.date && startDate.time > endDate.time);
