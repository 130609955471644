import { addMinutes, convertDecimal, onlyNumbers } from '@Zerf-dev/utils';

import { ROUTES } from '#constants/routes';

export const isLogged = expirationTime => {
  const currentDate = new Date();
  return expirationTime && addMinutes(currentDate, expirationTime) > currentDate;
};

// eslint-disable-next-line id-length
export const isNotOnboardingIfUserAlreadyDidIt = (didOnboarding, pathname) =>
  !(didOnboarding && pathname === ROUTES.LAWYER_ONBOARDING.path);

export const hasAccess = (tokenManager, supportedRoles, didOnboarding, pathname) =>
  tokenManager &&
  supportedRoles.includes(tokenManager.role) &&
  (tokenManager.justCompletedOnboarding || isNotOnboardingIfUserAlreadyDidIt(didOnboarding, pathname));

export const mapExpirationTime = string => convertDecimal(onlyNumbers(string), 10);
