import MenuItemTitle from '../MenuItem/components/MenuItemTitle';

import { usePersonalInformation } from './hooks';

function Profile() {
  const { name, email } = usePersonalInformation();
  return <MenuItemTitle titleClassName="w-36" title={name} subtitle={email} />;
}

export default Profile;
