import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import { createTask, deleteTask, getTasks, getWorkspaceTasks, updateTask } from '#services/TasksService';

import { TARGETS } from './constants';
import { postCheckoutInjections } from './models';

export const actions = createTypes(
  completeTypes(['GET_TASKS', 'CREATE_TASK', 'UPDATE_TASK', 'DELETE_TASK', 'GET_WORKSPACE_TASKS'], []),
  '@@TASKS'
);

const actionCreators = {
  getTasks: ({ legalCaseId, ...data }) => ({
    type: actions.GET_TASKS,
    target: TARGETS.SELECTED_TASKS,
    service: getTasks,
    payload: { legalCaseId, ...data }
  }),
  createTask: (data, currentUser, currentWorkspace) => ({
    type: actions.CREATE_TASK,
    target: TARGETS.TASK,
    service: createTask,
    payload: data,
    injections: postCheckoutInjections(data, currentUser, currentWorkspace)
  }),
  updateTask: ({ id, data }, currentUser, currentWorkspace, oldTask) => ({
    type: actions.UPDATE_TASK,
    target: TARGETS.TASK,
    service: updateTask,
    payload: { id, ...data },
    injections: postCheckoutInjections(data, currentUser, currentWorkspace, oldTask)
  }),
  deleteTask: (id, onSuccess) => ({
    type: actions.DELETE_TASK,
    target: TARGETS.DELETE_TASK,
    service: deleteTask,
    payload: id,
    injections: [withPostSuccess(onSuccess)]
  }),
  getWorkspaceTasks: data => ({
    type: actions.GET_WORKSPACE_TASKS,
    target: TARGETS.WORKSPACE_TASKS,
    service: getWorkspaceTasks,
    payload: data
  })
};

export default actionCreators;
