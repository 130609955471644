import i18next from 'i18next';

i18next.addResources('es', 'PJNHeader', {
  number: 'N° Expediente',
  credentials: 'Credenciales',
  jurisdiction: 'Jurisdicción',
  cover: 'Carátula',
  lastAction: 'Últ. Act.',
  status: 'Estado'
});
