import i18next from 'i18next';
import { useSelector } from 'react-redux';

import MenuItemTitle from '../../../MenuItem/components/MenuItemTitle';

function WorkspaceTitle() {
  const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
  return (
    <MenuItemTitle
      title={currentWorkspace?.name}
      subtitle={i18next.t('MenuItems:workspace')}
      titleClassName="w-48"
    />
  );
}

export default WorkspaceTitle;
