import { create, CancelToken } from 'apisauce';
import LocalStorage from '#services/LocalStorageService';
import { push } from 'connected-react-router';
import { ROUTES } from '#constants/routes';
import settingsActions from '#redux/Settings/actions';
import { REDIRECT_API_ERRORS } from '#constants/api';

export const createApiWithURL = baseURL =>
  create({
    baseURL,
    timeout: 5 * 60 * 1000
  });

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5 * 60 * 1000
});

export const getSource = () => CancelToken.source();

export const headers = {
  AUTHORIZATION: 'Authorization'
};

export const setAuthHeader = token => api.setHeader(headers.AUTHORIZATION, `Bearer ${token}`);

export const removeAuthHeader = () => api.deleteHeader(headers.AUTHORIZATION);

const RESPONSE_STATUS_CODES = {
  UNAUTHORIZED: 401
}

export const apiSetup = (baseApi, logout, dispatch) => {
  baseApi.addResponseTransform(response => {
    if (response.status === RESPONSE_STATUS_CODES.UNAUTHORIZED && !!LocalStorage.getTokenManager()) {
      logout?.();
    }

    if (REDIRECT_API_ERRORS.includes(response.problem)) {
      dispatch(settingsActions.setServerError(true));
      dispatch(push(ROUTES.ERROR.path));
    }
  });
};

export default api;
