import i18next from 'i18next';

i18next.addResources('es', 'MyCredentials', {
  title: 'Mis Credenciales',
  addCredentials: 'Agregar credenciales',
  confirmDelete: '¿Desea eliminar esta credencial?',
  cuitNumber: 'CUIT/CUIL: {{cuit}}',
  warningMessage:
    'Al realizar esta acción, se borrarán todos los expedientes cargados a Ubuntulaw con esta credencial.',
  notAvailableOption: 'Esta opción aún no está disponible',
  soonWillBeAvailable: 'Muy pronto le permitiremos sumar credenciales adicionales a su plataforma.'
});
