import { string } from 'prop-types';
import cn from 'classnames';

function MenuItemTitle({ title, subtitle, titleClassName }) {
  return (
    <div className="flex flex-row items-center">
      <div className="ml-3 flex h-10 flex-col">
        <p
          className={cn(
            'overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold leading-5 text-tuna',
            titleClassName
          )}>
          {title}
        </p>
        <p className="text-xs font-normal leading-5 text-neutral-500">{subtitle}</p>
      </div>
    </div>
  );
}

MenuItemTitle.propTypes = {
  subtitle: string,
  title: string,
  titleClassName: string
};

export default MenuItemTitle;
