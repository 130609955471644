import i18next from 'i18next';

export const MIXPANEL_EVENTS = {
  ACCOUNT_CREATED: i18next.t('Mixpanel:accountCreated'),
  CREDENTIAL_LOADED: i18next.t('Mixpanel:credentialLoaded'),
  FILE_IMPORTED: i18next.t('Mixpanel:fileImported'),
  PDF_OPENED: i18next.t('Mixpanel:pdfOpened'),
  CHECKOUT_SUCCESSFUL: i18next.t('Mixpanel:checkoutSuccessful'),
  CANCELLED_SUBSCRIPTION: i18next.t('Mixpanel:cancelledSubscription'),
  WORKSPACE_CREATED: i18next.t('Mixpanel:workspaceCreated'),
  TASK_CREATED: i18next.t('Mixpanel:taskCreated'),
  TASK_UPDATED: i18next.t('Mixpanel:taskUpdated'),
  EVIDENCE_UPLOADED: i18next.t('Mixpanel:evidenceUploaded'),
  CALENDAR_OPENED: i18next.t('Mixpanel:calendarOpened'),
  LEAVE_NOTE: i18next.t('Mixpanel:leaveNote'),
  ASSIGNMENT: i18next.t('Mixpanel:assignment')
};

export const ASSIGNMENT_TYPE = {
  FILE: 'file',
  TASK: 'task'
};
