import i18next from 'i18next';

i18next.addResources('es', 'WorkspaceInfo', {
  members: '{{count}} personas en este espacio',
  yourRole: 'Su rol asignado es ',
  lawyer: 'Colaborador',
  admin: 'Administrador',
  owner: 'Dueño',
  roleInformation: 'Información sobre roles'
});
