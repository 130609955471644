import { isEmptyObject, isObject, isValidDate } from '@Zerf-dev/utils';

import { isDate } from '#utils/date';
import { isEmpty } from '#utils/array';

export const removeKeys = (object, condition) => {
  if (isObject(object) && !Array.isArray(object)) {
    const newObject = {};
    Object.keys(object)
      .filter(key => !condition(object[key]))
      .forEach(key => {
        newObject[key] = removeKeys(object[key], condition);
      });
    return newObject;
  }
  return object;
};

export const removeFalsyValues = object => removeKeys(object, value => !value);

export const getIfExist = elem => (elem ? elem : undefined);

export const filterKeysByCondition = (object, condition) =>
  Object.fromEntries(Object.entries(object).filter(condition));

export const filterNonAdmittedKeys = (object, nonAdmittedKeys) =>
  filterKeysByCondition(object, ([key]) => !nonAdmittedKeys.includes(key));

export const omitKeys = (obj, keys) => {
  if (!obj) {
    return {};
  }
  if (!keys || keys.length === 0) {
    return obj;
  }
  return keys.reduce(
    (acc, key) => {
      delete acc[key];
      return acc;
    },
    { ...obj }
  );
};

export const reverseObjectOrder = object =>
  Object.keys(object)
    .reverse()
    .reduce((accumulator, key) => {
      accumulator[key] = object[key];
      return accumulator;
    }, {});

export const removeEmptyObjects = data =>
  Object.fromEntries(
    Object.entries(data).filter(([, value]) => value && (isValidDate(value) || !isEmptyObject(value)))
  );

export const removeEmptyValues = data => {
  const newObject = {};
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      newObject[key] = value;
    }
  });
  return newObject;
};

export const areEqualObjects = (object1, object2) =>
  Object.keys(object1).every(key =>
    isObject(object1[key]) ? areEqualObjects(object1[key], object2[key]) : object1[key] === object2[key]
  );

export const getObjectsValues = objects =>
  Object.entries(objects).reduce(
    (acc, [field, { values }]) =>
      acc.concat(Object.entries(values).map(([id, { label }]) => ({ field, id, label }))),
    []
  );

export const compareIds = id => object => object.id === id;

export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

const isComplexObject = obj => isObject(obj) && !Array.isArray(obj) && !isDate(obj);

export const mapAllObjectValues = (object, mapFunction) =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      Array.isArray(value) && !isEmpty(value)
        ? value.map(item => (isComplexObject(item) ? mapAllObjectValues(item, mapFunction) : item))
        : isComplexObject(value)
        ? mapAllObjectValues(value, mapFunction)
        : mapFunction(value)
    ])
  );
