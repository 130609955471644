import { func, string, bool, elementType } from 'prop-types';
import Modal from 'react-modal';
import cn from 'classnames';

import Button from '../Button';

import { noScroll } from '#utils/scroll';

import styles from './styles.module.scss';
import { useCustomModal } from './hooks';

function CustomModal({
  children,
  hideCloseButton,
  className,
  overlayClassName,
  shouldReturnFocusAfterClose,
  disableCloseButton,
  onClose,
  modal,
  defaultClassName,
  defaultAnimation,
  title,
  titleClassName,
  headerClassName,
  closeButtonClassName,
  CustomCloseIcon,
  Header,
  ...props
}) {
  const { isOpen, handleCloseModal, CloseIcon } = useCustomModal({
    modal,
    onClose,
    noScroll,
    CustomCloseIcon
  });

  return (
    <Modal
      {...props}
      closeTimeoutMS={350}
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      className={cn(
        {
          [styles.modalContainer]: defaultClassName,
          [styles.openAnimation]: defaultAnimation && isOpen,
          [styles.closeAnimation]: defaultAnimation && !isOpen
        },
        className
      )}
      overlayClassName={cn(
        styles.modalOverlay,
        overlayClassName,
        isOpen ? styles.overlayIn : styles.overlayOut
      )}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      bodyOpenClassName={styles.body}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}>
      {(title || !hideCloseButton) && (
        <div className={cn(styles.header, headerClassName)}>
          {title && <span className={cn(styles.title, titleClassName)}>{title}</span>}
          {Header && <Header />}
          {!hideCloseButton && (
            <Button
              className={closeButtonClassName || styles.closeButton}
              onClick={handleCloseModal}
              disabled={disableCloseButton}>
              <CloseIcon />
            </Button>
          )}
        </div>
      )}
      {children}
    </Modal>
  );
}

CustomModal.propTypes = {
  modal: string.isRequired,
  className: string,
  closeButtonClassName: string,
  CustomCloseIcon: elementType,
  defaultAnimation: bool,
  defaultClassName: bool,
  disableCloseButton: bool,
  Header: elementType,
  headerClassName: string,
  hideCloseButton: bool,
  overlayClassName: string,
  shouldReturnFocusAfterClose: bool,
  title: string,
  titleClassName: string,
  onClose: func
};

CustomModal.defaultProps = {
  defaultAnimation: true,
  defaultClassName: true,
  shouldReturnFocusAfterClose: false
};

export default CustomModal;
