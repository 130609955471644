import i18next from 'i18next';

i18next.addResources('es', 'PublicFileImport', {
  addPublicCase: 'Agregar exp. público',
  numberLabel: 'Número Expediente*',
  numberPlaceholder: 'Ingresar Número',
  yearLabel: 'Año Expediente*',
  yearPlaceholder: 'Ingresar Año',
  jurisdiction: 'Jurisdicción*',
  select: 'Seleccionar',
  obligatoryField: 'Campo obligatorio',
  search: 'Buscar',
  numberBiggerThanZero: 'El número debe ser mayor a 0',
  yearFourDigits: 'Mínimo de 4 dígitos',
  fileInTable: 'El expediente ya está en su lista',
  publicFileInTable: 'El expediente público ya está en su lista',
  notFound: 'No se encontró el expediente',
  checkInformation: 'Verifique la información ingresada',
  youCanSeeIt: 'Puede verlo en la tabla o buscar otro',
  add: 'Agregar',
  seeFile: 'Ver expediente',
  notRelated: 'No relacionado',
  lastAction: 'Última actuación:',
  importExtraCases: 'También podés importar Expedientes públicos del PJN y estar al tanto de sus movimientos',
  searchingFile: 'Estamos buscando el expediente...',
  thisCanTakeSomeMinutes: 'Esto puede tardar unos minutos.'
});
