import i18next from 'i18next';

i18next.addResources('es', 'FileDetails', {
  jurisdiction: 'Jurisdicción',
  dependence: 'Dependencia',
  status: 'Situación Actual',
  cover: 'Carátula',
  part: 'Parte',
  fileState: 'Estado',
  asignees: 'Personas asignadas:',
  assign: 'Asignar',
  cantAssign: 'Para poder asignar, seleccione un expediente.',
  plus: '+',
  seeAll: 'Ver todos',
  noAssign: 'Sin asignar',
  searchAssignee: 'Buscar asignado',
  searchCredential: 'Buscar credencial',
  credentials: 'Credenciales vinculadas:',
  emptyCredentials: 'No hay credenciales vinculadas',
  emptyAssignees: 'No hay personas asignadas',
  emptyNotAssignees: 'No hay personas para asignar',
  seeAllCredentials: 'Ver todas',
  notAssigned: 'No asignado'
});

i18next.addResources('es', 'Tabs', {
  actions: 'Actuaciones',
  generalData: 'Datos generales',
  evidence: 'Pruebas',
  notes: 'Notas',
  tasks: 'Tareas'
});

i18next.addResources('es', 'Header', {
  title: 'EXPEDIENTES PJN / <dec>N° {{number}}</dec>'
});
