export const MODALS = {
  LOGIN_ERROR: 'loginError',
  SIDE_BAR: 'sideBar',
  SIDE_MODAL: 'sideModal',
  CONTACT: 'contact',
  PJN_LOGIN_MODAL: 'pjnLoginModal',
  PJN_IMPORT_FILE_MODAL: 'pjnImportFileModal',
  IMAGE_CROPPER: 'imageCropper',
  EVIDENCE_UPLOAD_MODAL: 'evidenceUploadModal',
  EVIDENCE_REPORT_MODAL: 'evidenceReportModal',
  EXPERT_DATA_MODAL: 'expertDataModal',
  DATA_MODAL: 'dataModal',
  NOTIFICATION_CONFIGURATION: 'notificationConfiguration',
  CALENDAR_EVENT: 'calendarEvent',
  CREATE_EVENT: 'createEvent',
  RESULT_MODAL: 'resultModal',
  ADD_PUBLIC_FILE: 'addPublicFile',
  EDIT_NAME: 'editName',
  DELETE_CREDENTIAL: 'deleteCredential',
  CANNOT_ADD_CREDENTIAL: 'cannotAddCredential',
  SUBSCRIBE: 'subscribe',
  SCHEDULE_REPORT: 'scheduleReport',
  CHANGE_WORKSPACE: 'changeWorkspace',
  CHANGE_WORKSPACE_ERROR: 'changeWorkspaceError',
  NEW_WORKSPACE: 'newWorkspace',
  INVITE_MEMBER_MODAL: 'inviteMemberModal',
  DELETE_MEMBER_MODAL: 'deleteMemberModal',
  EDIT_MEMBER_MODAL: 'editMemberModal',
  ABANDON_WORKSPACE_MODAL: 'abandonWorkspaceModal',
  EDIT_WORKSPACE_NAME_MODAL: 'editWorkspaceNameModal',
  DELETE_WORKSPACE_MODAL: 'deleteWorkspaceModal',
  CREATE_WORKSPACE_MODAL: 'createWorkspaceModal',
  TASK_MODAL: 'taskModal',
  IMPORT_LIMIT: 'importLimit'
};
