/* eslint-disable id-length */
import { getQueryString } from '#utils/parseUrl';
import api from '#config/api';

export const importNotifications = data => api.get(`/notifications${getQueryString(data)}`);
export const postNotification = data => api.post('/notifications', data);
export const putNotification = ({ id, ...data }) => api.put(`/notifications/${id}`, data);
export const updateParticularNotificationConfig = ({ id, ...data }) =>
  api.post(`/legalCases/${id}/notificationConfig`, data);
export const deleteParticularNotificationConfig = id => api.delete(`/legalCases/${id}/notificationConfig`);
