import i18next from 'i18next';

i18next.addResources('es', 'LawyerOnboarding', {
  start: 'Comenzar',
  namePlaceholder: 'Escriba su/s nombre/s aquí',
  surnamePlaceholder: 'Escriba su/s apellido/s aquí',
  workspaceNamePlaceholder: 'Escriba el nombre aquí',
  logo: 'logo',
  admin: 'Administrador',
  lawyer: 'Colaborador',
  pressEnterToAddEmail: 'Toque la tecla “ENTER” para agregar el email',
  inviteUsers: 'Invitar usuarios',
  emailPlaceholder: 'Ingrese una dirección de email',
  role: 'Rol',
  next: 'Continuar',
  back: 'Volver',
  skipStep: 'Saltar paso',
  enterYourInformation: 'Ingrese información sobre usted',
  photoForProfile: 'Cargue una foto para su perfil de Ubuntulaw.',
  optional: 'Este paso es opcional.',
  createWorkspace: 'Cree su espacio de trabajo',
  createWorkspaceSubtitle:
    'Ubuntulaw le da la posibilidad de invitar y trabajar con colaboradores en sus expedientes.',
  workspacePicture: 'Cargue una foto para su espacio de trabajo.',
  inviteUsersTitle: 'Invite usuarios a su espacio',
  termsAndConditions: 'Acepto los',
  termsAndConditionsLink: 'términos y condiciones',
  termsAndConditionsRequired: 'Debe aceptar los términos y condiciones para continuar'
});
