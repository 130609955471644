export const FIELDS = {
  TASK_TITLE: 'title',
  TASK_DESCRIPTION: 'description',
  TASK_LINK: 'audienceLink',
  TASK_STATUS: 'status',
  TASK_ASSIGNEES: 'assignees',
  TASK_TYPE: 'type',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  REMINDER: 'reminder',
  LEGAL_CASE: 'legalCase',
  LEGAL_ACTION: 'legalAction',
  FILES: 'files',
  TASK: 'task',
  CASE_ASSIGNEES: 'caseAssignees'
};
