import i18next from 'i18next';

i18next.addResources('es', 'PJNTable', {
  title: 'EXPEDIENTES PJN',
  importErrorMessage: 'Para ver el detalle del expediente tiene que importarlo',
  searchBar: 'N° de expediente, carátula o dependencia',
  connectingToPJN: 'Conectando al Poder Judicial de la Nación...',
  noLoadedFiles: 'Aún no tiene expedientes cargados.',
  connectYourPJNCredential: 'Conecte su credencial del Poder Judicial de la Nación para comenzar.',
  connect: 'Conectar PJN',
  notAssigned: 'No puede ver el detalle del expediente porque no está asignado en él.',
  legalCases: 'expedientes'
});

i18next.addResources('es', 'Filters', {
  favorites: 'Favoritos',
  onlyFavorites: 'Solo favoritos'
});
