import { VALID_URL_PROTOCOLS } from '#constants/url';

export const isLastItemArray = (array, index) => index === array.length - 1;

const concat = (x, y) => x.concat(y);

export const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

export const flattened = arr => [].concat(...arr);

export const filterAndMap = (array, filterFunc, mapFunc, startItem) =>
  array.reduce(
    (accumItems, item) => (filterFunc(item, accumItems) ? mapFunc(item, accumItems) : accumItems),
    startItem
  );

export const containsAll = (arr1, arr2) => arr2.every(arr2Item => arr1.includes(arr2Item));

export const sameMembers = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);

export const getKeysArray = n => Array.from(Array(n).keys());

export const arrayWithoutValue = (array, value) => array.filter(v => v !== value);

export const arrayWithValue = (array, value) => [...array, value];

export const isEmptyElement = element => !element || !element.length;

export const isEmpty = array => array?.length === 0;

export const zip = (a, b) => a.map((k, i) => [k, b[i]]);

export const splitInHalf = array => {
  const firstArray = array.slice(0, Math.ceil(array.length / 2));
  const secondArray = array.slice(Math.ceil(array.length / 2), array.length);
  return { firstArray, secondArray };
};

export const sortAsTwoColumn = array => {
  const { firstArray, secondArray } = splitInHalf(array);
  return zip(firstArray, secondArray)
    .flat()
    .filter(element => !!element);
};

export const getSlicedItems = (items, max) => items?.slice(0, max);

export const deleteLastItem = items => getSlicedItems(items, -1);

export const getSelectedIndex = (steps, selectedStep) => steps.findIndex(({ id }) => id === selectedStep);

export const isRepeated = (value, values = []) => values.filter(v => v === value).length > 1;

export const removeEmptyValuesFromArray = array => array.filter(element => !!element);

export const isLink = value => VALID_URL_PROTOCOLS.some(protocol => value.startsWith(protocol));

export const convertToLink = value => (isLink(value) ? value : `https://${value}`);

export const removeElementByIndex = (array, index) => array.filter((_, i) => i !== index);

export const updateElementByIndex = (array, index, value) =>
  array.map((element, i) => (i === index ? value : element));

export const arrayIfCondition = (condition, array) => (condition ? array : []);

export const mapArray = (items, mappingFunction) =>
  isEmptyElement(items) ? items : items.map(mappingFunction);
