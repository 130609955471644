import i18next from 'i18next';

i18next.addResources('es', 'SelectLabel', {
  part: 'Parte',
  state: 'Estado',
  evidenceType: 'Tipo de evidencia'
});

i18next.addResources('es', 'SelectPlaceHolder', {
  part: 'Seleccionar parte',
  state: 'Seleccionar estado'
});
