import i18next from 'i18next';

i18next.addResources('es', 'InformativeEvidence', {
  searchBarPlaceholder: 'Oficio, pedido',
  response: 'Contest.',
  judicialOfficeBody: 'Cuerpo de oficio',
  judicialOffice: 'Oficio',
  request: 'Pedido',
  part: 'Parte',
  status: 'Estado'
});
