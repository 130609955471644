import i18next from 'i18next';

import { queryBuilder } from '#models/query';
import { CASES_TYPES, COLLECTIONS, LIST_FIELD_TYPE } from '#constants/legalCases';

export const getOptions = (resource, optionsID) =>
  Object.values(optionsID).map(value => ({
    label: i18next.t(`${resource}:${value}`),
    value
  }));

export const getRequestParams = (id, evidenceType, query = {}) => ({
  id,
  caseType: CASES_TYPES.PJN_FILE,
  listFieldType: LIST_FIELD_TYPE.EVIDENCES,
  evidenceType,
  ...queryBuilder({
    collection: COLLECTIONS.PJN_EVIDENCE,
    'evidenceType.type': evidenceType,
    ...query
  })
});

export const getPartName = part => (part ? `${part?.type} - ${part?.name}` : '');
