import { v4 as uuidv4 } from 'uuid';

import api, { createApiWithURL } from '#config/api';
import { VALID_S3_FILENAME_REGEX } from '#constants/s3';

export const getSignedUrl = data => api.post('/files/read_signed_url', data);

export const getWriteSignedUrl = ({ filename, extension, mimeType }) =>
  api.post('/files/write_signed_url', {
    name: VALID_S3_FILENAME_REGEX.test(filename) ? filename : uuidv4(),
    extension,
    mimeType
  });

export const uploadFileToS3 = async ({ file, contentType }) => {
  const [filename, extension] = file.name.split('.');

  try {
    const response = await getWriteSignedUrl({ filename, extension, mimeType: contentType });

    const payload = response.data;

    const formData = new FormData();

    Object.keys(payload.fields).forEach(field => {
      formData.append(field, payload.fields[field]);
    });

    formData.append('file', file);

    const imageUploadResponse = await createApiWithURL(payload.url).post('', formData, {
      headers: { 'Content-type': 'multipart/form-data' }
    });

    return imageUploadResponse;
  } catch (err) {
    return { ok: false, data: err };
  }
};

export const downloadCsv = (data, filename) => () => api.post('/files/csv', { data, filename });
