import i18next from 'i18next';

i18next.addResources('es', 'VinculatedFilesTable', {
  name: 'vinculados',
  searchBar: 'N° de expediente, carátula, dependencia'
});

i18next.addResources('es', 'VinculatedFilesHeaders', {
  file: 'Expediente',
  dependence: 'Dependencia',
  cover: 'Carátula',
  lastAction: 'Última actuación',
  status: 'Estado'
});
