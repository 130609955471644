import React, { Suspense } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import i18next from 'i18next';

import { getCurrentRoute } from '../../utils';
import AuthenticatedRoute from '../AuthenticatedRoute';
import styles from '../../styles.module.scss';

import { ROUTES } from '#constants/routes';
import Loading from '#components/LoadingWrapper/components/Loading';
import SideBar from '#components/SideBar';
import TopBanner from '#components/TopBanner';
import { useTopBanner } from '#hooks/useTopBanner';
import { WORKSPACE_STATUS } from '#constants/subscriptions';

const Routes = () => {
  const location = useLocation();
  const currentRoute = getCurrentRoute(location);
  const withMenu = !currentRoute?.noMenu;
  const withTopBanner = !currentRoute?.noTopBanner;
  const currentUser = useSelector(state => state.auth.currentUser);
  const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
  const scraperHealth = useSelector(state => state.settings.scraperHealth);
  const { hideBanner, bannerStatus, bannerTitle, bannerSubtitleKey, bannerLink } = useTopBanner({
    currentWorkspace: {
      ...currentWorkspace,
      onFreeTrial: currentWorkspace?.subscriptionStatus === WORKSPACE_STATUS.FREE_TRIAL
    },
    scraperHealth
  });

  return (
    <div
      className={cn(styles.container, {
        [styles.withOverflow]: currentRoute?.withOverflow
      })}>
      {withMenu && currentUser && <SideBar canBeHided={false} />}
      <div
        className={cn(styles.contentContainer, {
          [styles.withMenu]: withMenu
        })}>
        {withTopBanner && !hideBanner && (
          <TopBanner
            status={bannerStatus}
            title={bannerTitle}
            subtitleKey={bannerSubtitleKey}
            link={bannerLink}
          />
        )}
        <Suspense fallback={Loading}>
          {Object.values(ROUTES).map(({ path, ...routeProps }) => (
            <AuthenticatedRoute path={path} key={path} {...routeProps} />
          ))}
          <CookieConsent
            disableStyles
            buttonText={i18next.t('CookieConsent:accept')}
            containerClasses="fixed left-0 z-[999] flex w-full flex-row flex-wrap items-baseline justify-between !bg-neutral-500 text-white"
            contentClasses="m-4 font-barlow font-bold"
            buttonClasses="m-4 w-28 rounded border-sm border-solid border-primary-300 bg-white stroke-primary-300 px-2 py-1 text-primary-300 hover:border-primary-500 hover:bg-secondary-100 hover:stroke-primary-500 hover:text-primary-500">
            {i18next.t('CookieConsent:message')}
          </CookieConsent>
        </Suspense>
      </div>
    </div>
  );
};

export default Routes;
