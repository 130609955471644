import i18next from 'i18next';

i18next.addResources('es', 'Table', {
  searchBar: 'N° expediente, dependencia, carátula, etc',
  searchButton: 'Buscar',
  filterButton: 'Filtrar',
  lastUpdate: 'Última actualización:',
  lastUpdateTime: '{{time}}hs',
  importedCases: 'Expedientes importados'
});
