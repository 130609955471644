import cn from 'classnames';
import { string, func, shape, bool } from 'prop-types';

import useMenuItem from '../useMenuItem';
import SubmenuTitle from '../SubmenuTitle';
import styles from '../styles.module.scss';
import IconItem from '../IconItem';

import IconButton from '#components/IconButton';

import SubMenu from './components/SubMenu';

/* eslint-disable complexity */
function MenuItem({
  item,
  onClick,
  itemClassName,
  iconClassName,
  labelClassName,
  iconContainerClassName,
  isOpen,
  menuItemSelected,
  menuItemHovered,
  menuItemCloseSelected,
  menuItemNotSelected
}) {
  const {
    setHover,
    ImgComponent,
    ItemComponent,
    isSelectedOrHovering,
    openSubMenu,
    isSelected,
    subMenuOpen,
    isHovering,
    handleClick,
    RightButtonIcon
  } = useMenuItem({ item, onClick });

  return (
    <div className={styles.container}>
      <ItemComponent
        to={item.route?.path}
        className={styles.innerContainer}
        onClick={item?.onClick || handleClick}>
        <div
          onMouseEnter={setHover(true)}
          onMouseLeave={setHover(false)}
          className={cn(
            {
              [menuItemSelected || styles.menuItemSelected]: isSelected && isOpen,
              [menuItemNotSelected || styles.menuItemNotSelected]: !isSelected,
              [menuItemHovered || styles.menuItemHovered]: isHovering && !isSelected,
              [styles.secondary]: isHovering && !!item?.secondary,
              [menuItemCloseSelected || styles.menuItemCloseSelected]: isSelected && !isOpen
            },
            itemClassName
          )}>
          <IconItem
            imgComponent={ImgComponent}
            isSelected={isSelected}
            isHovering={isHovering}
            isSelectedOrHovering={isSelectedOrHovering}
            item={item}
            className={iconContainerClassName}
            iconClassName={iconClassName}
          />
          <SubmenuTitle
            item={item}
            labelClassName={cn(labelClassName || styles.label, {
              [styles.labelWeightSelected]: isSelected,
              [styles.labelWeightNotSelected]: !isSelected
            })}
            isSelected={isSelected}
            subMenuClassName={cn({ [styles.subMenuHidden]: !isOpen })}
            subMenuOpen={subMenuOpen}
            openSubMenu={openSubMenu}
          />
        </div>
        <SubMenu item={item} subMenuOpen={subMenuOpen} />
      </ItemComponent>
      {item?.rightButton && (
        <IconButton
          onClick={item?.rightButton?.onClick}
          containerClassName={cn(styles.rightButton, { [styles.subMenuHidden]: !isOpen })}>
          <RightButtonIcon />
        </IconButton>
      )}
    </div>
  );
}

MenuItem.propTypes = {
  item: shape({
    name: string.isRequired,
    iconOn: string.isRequired,
    iconOff: string.isRequired,
    secondary: bool
  }).isRequired,
  iconClassName: string,
  iconContainerClassName: string,
  isOpen: bool,
  itemClassName: string,
  labelClassName: string,
  menuItemCloseSelected: string,
  menuItemHovered: string,
  menuItemNotSelected: string,
  menuItemSelected: string,
  onClick: func
};

MenuItem.defaultProps = {
  iconClassName: '',
  iconContainerClassName: '',
  itemClassName: '',
  labelClassName: ''
};

export default MenuItem;
