import i18next from 'i18next';

i18next.addResources('es', 'TestimonialEvidence', {
  audienceRecord: 'Acta de audiencia',
  digitalDocument: 'Documento Digital',
  audienceDate: 'Fecha y Hs de audiencia',
  witnessesNames: 'Nombres/s testigo/s',
  callNumber: 'N° de llamado',
  witnessData: 'Datos testigo/s',
  virtualAudience: 'Audiencia virtual',
  searchBarPlaceholder: 'Nombre de testigo'
});
