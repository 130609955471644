import i18next from 'i18next';

i18next.addResources('es', 'IntervenersTables', {
  searchBar: 'Nombre, tipo, profesión',
  writeEmail: 'Escriba un email',
  number: 'Número'
});

i18next.addResources('es', 'IntervenersTableTitles', {
  parts: 'Partes intervinientes',
  experts: 'Peritos intervinientes',
  prosecutors: 'Fiscales intervinientes'
});

i18next.addResources('es', 'IntervenersTableHeaders', {
  type: 'Tipo',
  name: 'Nombre',
  email: 'Email',
  contact: 'Contacto',
  profession: 'Profesión',
  prosecution: 'Fiscalía',
  prosecutor: 'Fiscal',
  dependence: 'Dependencia',
  cover: 'Carátula',
  status: 'Estado',
  lastAction: 'Última Actuación',
  file: 'Expediente'
});

i18next.addResources('es', 'IntervenersErrors', {
  invalidEmail: 'El email ingresado no es válido',
  invalidPhone: 'El número ingresado no es válido',
  notFound: 'No hay resultados que coincidan con “{{search}}”',
  tryAgain: 'Intente con otra búsqueda',
  noParts: 'No hay {{name}}',
  relevantData: 'Los datos relevantes se mostrarán aquí'
});
