import { WORKSPACE_STATUS } from './subscriptions';

export const TOP_BANNER_STATUS = {
  NORMAL: WORKSPACE_STATUS.NORMAL,
  FREE_TRIAL: WORKSPACE_STATUS.FREE_TRIAL,
  SUBSCRIPTION_CANCELLED: WORKSPACE_STATUS.SUBSCRIPTION_CANCELLED,
  FREE_TRIAL_ENDING: WORKSPACE_STATUS.FREE_TRIAL_ENDING,
  FREE_TRIAL_OVER: WORKSPACE_STATUS.FREE_TRIAL_OVER,
  SUBSCRIPTION_OVER: WORKSPACE_STATUS.SUBSCRIPTION_OVER,
  SUBSCRIPTION_PAUSED: WORKSPACE_STATUS.SUBSCRIPTION_PAUSED,
  SCRAPER_NOT_HEALTHY: 'SCRAPER_NOT_HEALTHY'
};
