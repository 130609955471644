import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import CasesActions from '#redux/LegalCases/actions';
import {
  abandonWorkspace,
  acceptInvitation,
  createWorkspace,
  deleteInvitation,
  deleteMember,
  editWorkspace,
  deleteWorkspace,
  getCredentialsOfWorkspace,
  getInviteIdOfWorkspace,
  getMembersOfWorkspace,
  getWorkspace,
  getWorkspaceById,
  inviteToWorkspace,
  assignUser,
  unassignUser
} from '#services/WorkspaceService';
import LocalStorage from '#services/LocalStorageService';
import MixpanelService from '#services/MixpanelService';
import { ASSIGNMENT_TYPE, MIXPANEL_EVENTS } from '#constants/mixpanel';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'CREATE_WORKSPACE',
      'GET_CURRENT_WORKSPACE',
      'GET_WORKSPACE',
      'ACCEPT_INVITATION',
      'DELETE_INVITATION',
      'GET_INVITE_ID_OF_WORKSPACE',
      'GET_CREDENTIALS_OF_WORKSPACE',
      'GET_MEMBERS_OF_WORKSPACE',
      'INVITE_TO_WORKSPACE',
      'DELETE_MEMBER',
      'ABANDON_WORKSPACE',
      'EDIT_WORKSPACE',
      'DELETE_WORKSPACE',
      'ASSIGN_USER',
      'UNASSIGN_USER'
    ],
    ['ADD_LEAVE_NOTE']
  ),
  '@@WORKSPACES'
);

const WorkspaceActions = {
  createWorkspace: (data, onSuccess) => ({
    type: actions.CREATE_WORKSPACE,
    target: TARGETS.CREATE_WORKSPACE,
    service: createWorkspace,
    payload: data,
    injections: [
      withPostSuccess((dispatch, response) => {
        onSuccess?.(response);
        dispatch(WorkspaceActions.getCurrentWorkspace());
      })
    ]
  }),
  getCurrentWorkspace: (onSuccess, getExtraData = true) => ({
    type: actions.GET_CURRENT_WORKSPACE,
    target: TARGETS.CURRENT_WORKSPACE,
    service: getWorkspace,
    injections: [
      withPostSuccess(dispatch => {
        if (getExtraData) {
          dispatch(WorkspaceActions.getMembersOfWorkspace());
          dispatch(WorkspaceActions.getCredentialsOfWorkspace(onSuccess));
        } else {
          onSuccess?.();
        }
      })
    ]
  }),
  getWorkspaceById: (id, onSuccess) => ({
    type: actions.GET_WORKSPACE,
    target: TARGETS.GET_WORKSPACE,
    service: getWorkspaceById,
    payload: id,
    injections: [withPostSuccess(onSuccess), withPostFailure(() => LocalStorage.removeInvitationManager())]
  }),
  acceptInvitation: (id, onSuccess) => ({
    type: actions.ACCEPT_INVITATION,
    target: TARGETS.ACCEPT_INVITATION,
    service: acceptInvitation,
    payload: id,
    injections: [withPostSuccess(onSuccess)]
  }),
  deleteInvitation: ({ workspace, inviteId }, onSuccess) => ({
    type: actions.DELETE_INVITATION,
    target: TARGETS.DELETE_INVITATION,
    service: deleteInvitation,
    payload: { workspace, inviteId },
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        dispatch(WorkspaceActions.getMembersOfWorkspace());
      })
    ]
  }),
  getInviteIdOfWorkspace: (workspace, inviteId) => ({
    type: actions.GET_INVITE_ID_OF_WORKSPACE,
    target: TARGETS.CURRENT_INVITE_ID,
    service: getInviteIdOfWorkspace,
    payload: { workspace, inviteId }
  }),
  getCredentialsOfWorkspace: onSuccess => ({
    type: actions.GET_CREDENTIALS_OF_WORKSPACE,
    target: TARGETS.CREDENTIALS_OF_WORKSPACE,
    service: getCredentialsOfWorkspace,
    injections: [withPostSuccess(onSuccess)]
  }),
  getMembersOfWorkspace: payload => ({
    type: actions.GET_MEMBERS_OF_WORKSPACE,
    target: TARGETS.MEMBERS_OF_WORKSPACE,
    payload,
    service: getMembersOfWorkspace
  }),
  inviteToWorkspace: (data, onSuccess) => ({
    type: actions.INVITE_TO_WORKSPACE,
    target: TARGETS.INVITE_TO_WORKSPACE,
    service: inviteToWorkspace,
    payload: data,
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        dispatch(WorkspaceActions.getMembersOfWorkspace());
      })
    ]
  }),
  deleteMember: (id, onSuccess) => ({
    type: actions.DELETE_MEMBER,
    service: deleteMember,
    payload: id,
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        dispatch(WorkspaceActions.getMembersOfWorkspace());
      })
    ]
  }),
  abandonWorkspace: onSuccess => ({
    type: actions.ABANDON_WORKSPACE,
    service: abandonWorkspace,
    injections: [withPostSuccess(onSuccess)]
  }),
  editWorkspace: (payload, onSuccess) => ({
    type: actions.EDIT_WORKSPACE,
    payload,
    service: editWorkspace,
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        dispatch(WorkspaceActions.getCurrentWorkspace());
      })
    ]
  }),
  deleteWorkspace: onSuccess => ({
    type: actions.DELETE_WORKSPACE,
    service: deleteWorkspace,
    injections: [withPostSuccess(onSuccess)]
  }),
  assignUser: ({ legalCaseId, assigneeId }, onSuccess, currentUser, currentWorkspace) => ({
    type: actions.ASSIGN_USER,
    service: assignUser,
    payload: { legalCaseId, assigneeId },
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        MixpanelService.track(MIXPANEL_EVENTS.ASSIGNMENT, {
          userId: currentUser?.id,
          userSurname: currentUser?.surname,
          userEmail: currentUser?.email,
          workspaceId: currentUser?.workspace,
          workspaceName: currentWorkspace?.name,
          type: ASSIGNMENT_TYPE.FILE,
          assignedUserId: assigneeId
        });
        dispatch(CasesActions.getCaseById(legalCaseId));
      })
    ]
  }),
  unassignUser: ({ legalCaseId, assigneeId }, onSuccess) => ({
    type: actions.UNASSIGN_USER,
    service: unassignUser,
    payload: { legalCaseId, assigneeId },
    injections: [
      withPostSuccess(dispatch => {
        onSuccess?.();
        dispatch(CasesActions.getCaseById(legalCaseId));
      })
    ]
  }),
  addLeaveNote: data => ({
    type: actions.ADD_LEAVE_NOTE,
    target: TARGETS.CURRENT_WORKSPACE,
    payload: data
  })
};

export default WorkspaceActions;
