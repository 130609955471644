import i18next from 'i18next';

i18next.addResources('es', 'CaseStatus', {
  letter: 'En letra',
  office: 'En despacho',
  turn: 'En giro'
});

i18next.addResources('es', 'ActionStatus', {
  incorporated: 'Escrito incorporado',
  added: 'Escrito agregado',
  officeSignature: 'Firma despacho',
  movement: 'Movimiento',
  stateChanges: 'Cambios de estado'
});

i18next.addResources('es', 'FileStatus', {
  groupName: 'Estado',
  office: 'En despacho',
  letter: 'En letra',
  turn: 'En giro',
  loan: 'En préstamo',
  remision: 'En remisión'
});

i18next.addResources('es', 'FileState', {
  groupName: 'Estado de expediente',
  evidenceGroupName: 'Estado de prueba',
  inProcess: 'En trámite',
  completed: 'Realizada',
  contested: 'Impugnada',
  reiterated: 'Reiterado',
  finalized: 'Finalizado',
  answered: 'Contestado'
});

i18next.addResources('es', 'Part', {
  groupName: 'Parte',
  plaintiff: 'Actora',
  defendant: 'Demandada',
  other: 'Otra'
});

i18next.addResources('es', 'EvidenceStatus', {
  completed: 'Realizada',
  contested: 'Impugnada',
  reiterated: 'Reiterada'
});

i18next.addResources('es', 'ActionsType', {
  groupName: 'Tipo de actuación',
  stateChanges: 'Cambios de estado',
  writed: 'Escritos agregados / incorporados',
  office: 'Despachos',
  others: 'Otros'
});

i18next.addResources('es', 'PublicStatus', {
  notRelated: 'No relacionado',
  related: 'Relacionado'
});

i18next.addResources('es', 'FileType', {
  groupName: 'Mostrar',
  favorites: 'Favoritos',
  assigned: 'Mis asignados',
  hidden: 'Ocultos'
});

i18next.addResources('es', 'FileJurisdiction', {
  groupName: 'Jurisdicción',
  all: 'Todas las jurisdicciones'
});
