import { string, bool, shape, oneOf } from 'prop-types';
import Lottie from 'lottie-react';
import cn from 'classnames';

import { getOptions, SPINNER_TYPES } from './constants';
import styles from './styles.module.scss';

function Loading({ className, lottieStyle, type, loop, autoplay, animation }) {
  return (
    <div className={cn(styles.spinnerContainer, className)}>
      <Lottie style={lottieStyle} {...getOptions({ type, loop, autoplay, animation })} />
    </div>
  );
}

Loading.propTypes = {
  animation: string,
  autoplay: bool,
  className: string,
  loop: bool,
  lottieStyle: shape({
    height: string,
    width: string,
    margin: string
  }),
  type: oneOf(Object.keys(SPINNER_TYPES))
};

Loading.defaultProps = {
  lottieStyle: {
    height: '124px',
    width: '150px',
    margin: 'auto'
  }
};

export default Loading;
