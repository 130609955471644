export const ROLES = {
  LAWYER: 'lawyer',
  ADMIN: 'admin',
  OWNER: 'owner'
};

export const ALL_ROLE_VALUES = Object.values(ROLES);

export const PATHNAME_REDIRECT_PARAM = 'redirectTo';

export const NAME_IDS = {
  NAME: 'name',
  SURNAME: 'surname'
};

export const REMAINING_DAYS_FOR_ALERT = 10;
