import { isBeforeNow, addDays, getNumberOfDaysBetween } from '@Zerf-dev/utils';
import i18next from 'i18next';

import { WORKSPACE_STATUS, SUBSCRIPTION_STATUS, FREE_TRIAL_STATUSES } from '#constants/subscriptions';
import { ROLES, REMAINING_DAYS_FOR_ALERT } from '#constants/users';
import { currentDate } from '#utils/date';

export const INVITATION_STATUS = {
  PENDING: 'pending',
  EXPIRED: 'expired'
};

export const isPending = invitationStatus => invitationStatus === INVITATION_STATUS.PENDING;
export const isExpired = invitationStatus => invitationStatus === INVITATION_STATUS.EXPIRED;

export const INPUT_EMAIL = 'currentEmail';

export const isOwner = role => role === ROLES.OWNER;
export const isAdmin = role => role === ROLES.ADMIN;
export const isCollaborator = role => role === ROLES.LAWYER;

export const isOnFreeTrial = workspace => workspace?.subscriptionStatus === WORKSPACE_STATUS.FREE_TRIAL;

export const isFreeTrialOver = workspace =>
  isBeforeNow(workspace?.freeTrialEndDate || addDays(currentDate(), 1));

export const getFreeTrialEndDate = workspace =>
  workspace && workspace.freeTrialEndDate && new Date(workspace.freeTrialEndDate);

const daysRemainingOfFreeTrial = workspace =>
  getNumberOfDaysBetween(
    currentDate(),
    getFreeTrialEndDate(workspace) || addDays(currentDate(), REMAINING_DAYS_FOR_ALERT + 1)
  );

export const onLessThanTenDaysOfFreeTrial = workspace =>
  workspace && isOnFreeTrial(workspace) && daysRemainingOfFreeTrial(workspace) <= REMAINING_DAYS_FOR_ALERT;

export const isSubscribed = workspace =>
  workspace?.mercadoPagoSubscription?.status &&
  workspace.mercadoPagoSubscription.status !== SUBSCRIPTION_STATUS.CANCELLED;

export const isFreeTrialStatus = status => FREE_TRIAL_STATUSES.includes(status);

export const getSubscription = (subscriptionStatus, statusText, currentWorkspace) => {
  const subscription = isFreeTrialStatus(subscriptionStatus)
    ? statusText
    : i18next.t(`SubscriptionPlans:${currentWorkspace?.mercadoPagoSubscription?.plan}Subscription`);
  return subscription?.toUpperCase();
};
