export const TARGETS = {
  SIGN_IN: 'signIn',
  CURRENT_USER: 'currentUser',
  CHECK_EMAIL: 'checkEmail',
  SIGN_IN_PARAMS: 'signInParams',
  UPDATE_USER: 'updateUser',
  CREATE_CREDENTIAL: 'createCredential',
  SET_CREATE_CREDENTIAL_ERROR: 'errorCreatingCredential',
  CURRENT_CREDENTIALS: 'currentCredentials',
  DELETE_CREDENTIAL: 'deleteCredential'
};

export const CREATE_CREDENTIAL_ERRORS = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  SERVER_ERROR: 'SERVER_ERROR'
};

export const INTERNAL_CODES = {
  1133: CREATE_CREDENTIAL_ERRORS.UNAUTHORIZED
};
