import { objectToSnakeCase } from '@Zerf-dev/utils';
import mixpanel from 'mixpanel-browser';

const init = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL, { debug: process.env.NODE_ENV === 'development' });
};

const track = (eventName, properties = {}) => {
  mixpanel.track(eventName, objectToSnakeCase(properties));
};

const MixpanelService = {
  init,
  track,
  identify: mixpanel.identify
};

export default MixpanelService;
