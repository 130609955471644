export const CASES_TYPES = {
  PJN_FILE: 'pjnFile'
};

export const LIST_FIELD_TYPE = {
  ACTIONS: 'actions',
  PARTICIPANTS: 'participants',
  PROSECUTORS: 'prosecutors',
  EXPERTS: 'experts',
  INVOLVED_FILES: 'involvedFiles',
  EVIDENCES: 'evidences',
  NOTES: 'notes'
};

export const COLLECTIONS = {
  LEGAL_CASES: 'legalcases',
  PJN_ACTIONS: 'pjnactions',
  PJN_EXPERTS: 'pjnexperts',
  PJN_PARTICIPANTS: 'pjnparticipants',
  PJN_PROSECUTORS: 'pjnprosecutors',
  INVOLVED_FILES: 'involvedFiles',
  PJN_EVIDENCE: 'pjnevidences',
  PJN_NOTIFICATIONS: 'pjnnotifications',
  PJN_NOTES: 'pjnnotes',
  AGENDA_EVENTS: 'agendaevents',
  TASKS: 'tasks'
};

export const SUCCESS_TIMEOUT = 2000;

export const IMPORT_STATES = {
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  LOADING: 'loading',
  IMPORTED: 'imported',
  FAILED: 'failed',
  SUCCESS: 'success'
};

export const IMPORT_STATES_SHOWN = [
  IMPORT_STATES.SCHEDULED,
  IMPORT_STATES.LOADING,
  IMPORT_STATES.FAILED,
  IMPORT_STATES.SUCCESS
];

export const IMPORT_EVENTS_TO_BIND = [
  {
    event: 'import-file-scheduled',
    importState: IMPORT_STATES.SCHEDULED
  },
  {
    event: 'import-file-start',
    importState: IMPORT_STATES.LOADING
  },
  {
    event: 'import-file-error',
    importState: IMPORT_STATES.FAILED
  },
  {
    event: 'import-file-end',
    importState: IMPORT_STATES.SUCCESS,
    shouldSetToImported: true
  }
];

export const PJN_LEAVE_NOTE_EVENT = 'pjn-leave-note';
export const TASKS_UPDATED_PUSHER_EVENT = 'tasks-updated';

export const SEARCH_CASE_EVENT = 'search-public-file';

export const CASE_SEARCH_RESULTS = {
  PUBLIC_AND_ADDED: 'publicAndAdded',
  PUBLIC: 'public',
  RELATED: 'related',
  HIDDEN: 'hidden',
  NOT_FOUND: 'notFound'
};

export const LEGAL_CASE_QUERY = {
  caseType: CASES_TYPES.PJN_FILE,
  [COLLECTIONS.LEGAL_CASES]: {
    filter: {
      isImported: true
    }
  }
};
