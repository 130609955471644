import { getQueryString } from '#utils/parseUrl';
import api, { getSource } from '#config/api';

export const API_SOURCE_TOKENS = {
  getLegalCase: getSource()
};

export const getLegalCases = data => {
  API_SOURCE_TOKENS.getLegalCase.cancel();
  API_SOURCE_TOKENS.getLegalCase = getSource();
  return api.get(
    `/legalCases${getQueryString(data)}`,
    {},
    { cancelToken: API_SOURCE_TOKENS.getLegalCase.token }
  );
};

export const importLegalCases = data => api.post('/legalCases', data);
export const importLegalCase = ({ id, ...data }) => api.put(`/legalCases/${id}/import`, data);
export const getLegalCaseById = id => api.get(`/legalCases/${id}`);
export const getLegalCaseListField = ({ id, ...data }) =>
  api.get(`legalCases/${id}/listField${getQueryString(data)}`);
export const updateLegalCaseListField = ({ legalCaseId, id, ...data }) =>
  api.put(`legalCases/${legalCaseId}/listField/${id}`, data);
export const updateLegalCase = ({ id, ...data }) => api.put(`/legalCases/${id}`, data);
export const uploadEvidence = ({ id, evidence }) => api.post(`/legalCases/${id}/evidences`, evidence);
export const deleteEvidence = ({ legalCaseId, evidenceId }) =>
  api.delete(`/legalCases/${legalCaseId}/evidences/${evidenceId}`);
export const updateEvidence = ({ id, evidenceId, evidence }) =>
  api.put(`/legalCases/${id}/evidences/${evidenceId}`, evidence);
export const updateAction = ({ id, ...data }) => api.put(`/actions/${id}`, data);
export const searchPublicFile = data => api.post('/legalCases/public/search', data);
export const addPublicFile = id => api.post(`/legalCases/public/add/${id}`);
export const leaveNote = id => api.put(`/legalCases/${id}/leaveNote`);
