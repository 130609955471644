import i18next from 'i18next';

i18next.addResources('es', 'Information', {
  total: 'Total',
  monthly: 'Mensual',
  annual: 'Anual',
  subscriptionType: 'Tipo de suscripción',
  planType: 'Tipo de plan',
  paymentType: 'Tipo de pago',
  basic: 'Básica',
  standard: 'Estándar',
  premium: 'Prémium',
  perMonth: '{{price}}ARS / mes',
  perYear: '{{price}}ARS / año',
  onePaymentOf: '1 pago de {{price}}ARS',
  ars: 'ARS',
  paymentMethod: 'Medio de pago actual',
  nextPaymentDate: 'Próxima fecha de pago'
});
