import i18next from 'i18next';

i18next.addResources('es', 'ActionTable', {
  pdf: 'PDF',
  description: 'Descripción',
  type: 'Tipo',
  presentationDate: 'Fecha de presentación',
  loadDate: 'Fecha de carga a PJN',
  searchBar: 'Buscar por descripción o tipo'
});

i18next.addResources('es', 'Comments', {
  writeComment: 'Escribir comentario...',
  comment: 'Comentar',
  mention: 'Puede etiquetar usuarios de su espacio de trabajo usando el símbolo @',
  emptyAssignees: 'Usuario inexistente'
});

i18next.addResources('es', 'ActionTableFilters', {
  pjnLoadDate: 'Carga a PJN por fecha/rango'
});
