import { createRoot } from 'react-dom/client';

import './config/i18n';
import 'react-toastify/dist/ReactToastify.css';
import './scss/application.scss';
import './scss/global.css';
import App from './app';

if (process.env.NODE_ENV === 'production') {
  import('./scss/tailwind.css');
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
