export const BULLET_TYPES = {
  GENERAL_CONFIGURATION: 'generalConfiguration',
  INDIVIDUAL_CONFIGURATION: 'individualConfiguration'
};

export const BULLETS = [
  {
    id: BULLET_TYPES.GENERAL_CONFIGURATION
  },
  {
    id: BULLET_TYPES.INDIVIDUAL_CONFIGURATION
  }
];
