import i18next from 'i18next';

i18next.addResources('es', 'ImageCropper', {
  advice: 'Consejos para una buena foto',
  load: 'Cargue una foto personal',
  illumination: 'Asegúrese de que su fotografía cuente con una buena iluminación',
  frontPhoto: 'Se recomienda seleccionar una foto de frente',
  addPhoto: 'Cargar foto',
  upload: 'Subir',
  cancel: 'Cancelar'
});
