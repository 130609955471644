import { func } from 'prop-types';

import { ReactComponent as CloseIcon } from '#assets/close-toast.svg';

import styles from './styles.module.scss';

function ToastCloseButton({ closeToast }) {
  return (
    <div className={styles.container}>
      <CloseIcon className={styles.icon} onClick={closeToast} />
    </div>
  );
}

ToastCloseButton.propTypes = {
  closeToast: func
};

export default ToastCloseButton;
