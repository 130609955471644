import i18next from 'i18next';

import { CASES_TYPES } from '#constants/legalCases';

export const PAGE_SIZE = 8;
export const ANIMATION_DURATION = 400;

export const SEARCH_GROUP = {
  placeholder: i18next.t('PJNTable:searchBar'),
  values: [
    {
      field: 'caseType.typeFields.number'
    },
    {
      field: 'caseType.typeFields.dependence'
    },
    {
      field: 'caseType.typeFields.cover'
    }
  ]
};

export const GET_CASES_INITIAL_PARAMS = {
  caseType: CASES_TYPES.PJN_FILE,
  legalcases: { sort: { 'caseType.typeFields.lastAction': -1 } }
};

export const TIME_TO_REMOVE_QUERY = 2000;
