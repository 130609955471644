import i18next from 'i18next';

import { ROLES } from '#constants/users';

const { ADMIN, OWNER } = ROLES;

i18next.addResources('es', 'WorkspaceConfiguration', {
  title: 'CONFIGURACIÓN',
  cancel: 'Cancelar',
  save: 'Guardar',
  abandon: 'Abandonar espacio de trabajo',
  logout: 'Salir de Ubuntulaw',
  name: 'Nombre del espacio',
  subscriptionTitle: 'Suscripción',
  subscriptionDescription: 'Revise el estado de la suscripción en su perfil personal.',
  profile: 'Ir a mi perfil',
  account: 'Cuenta',
  [`account${ADMIN}`]:
    'Asegúrese de que haya otros usuarios administradores antes de intentar abandonar este espacio de trabajo. ',
  [`account${OWNER}`]:
    'Asegúrese de transferir su rol de dueño a otro usuario antes de intentar abandonar este espacio de trabajo. ',
  delete: 'Borrar espacio de trabajo',
  changeNameModal: 'CAMBIAR NOMBRE DE ESPACIO DE TRABAJO',
  workspaceName: 'Nombre de espacio de trabajo',
  create: 'Crear espacio de trabajo',
  deleteWorkspaceModal: '¿BORRAR ESPACIO DE TRABAJO?',
  deleteWorkspace: 'Usted va a eliminar el espacio de trabajo:',
  deleteDescription:
    'Perderá acceso a todos los expedientes y tareas de este espacio. Deberá crear o ser invitado un nuevo espacio de trabajo para poder continuar utilizando Ubuntulaw.',
  createWorkspaceModal: 'ESPACIO DE TRABAJO BORRADO',
  createWorkspace: 'Usted eliminó el espacio de trabajo:',
  createDescription:
    'Deberá crear o ser invitado un nuevo espacio de trabajo para poder continuar utilizando Ubuntulaw.',
  recoverWorkspace: 'El dueño de este espacio tendrá hasta 30 días para deshacer esta acción.'
});
