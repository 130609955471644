import i18next from 'i18next';

import { MODALS } from '#redux/Modal/constants';

const { SUBSCRIBE, IMPORT_LIMIT } = MODALS;

i18next.addResources('es', 'ActionModal', {
  [`${SUBSCRIBE}title`]: 'Suscríbase para seguir usando Ubuntulaw',
  [`${SUBSCRIBE}description`]: 'Adhiérase a un plan para continuar su gestión profesional.',
  [`${SUBSCRIBE}action`]: 'Ver planes',
  [`${IMPORT_LIMIT}title`]: 'Ha llegado al máximo de importaciones de expedientes permitidas en su {{value}}',
  [`${IMPORT_LIMIT}description`]: 'Para poder seguir importando, puede conseguir una nueva suscripción.',
  [`${IMPORT_LIMIT}adminDescription`]:
    'Para adquirir una nueva suscripción, comuníquese con el Dueño del espacio de trabajo.',
  [`${IMPORT_LIMIT}action`]: 'Ver planes'
});
