import { ReactComponent as InfoIcon } from '#assets/info-free-trial.svg';
import { ReactComponent as AlertIcon } from '#assets/alert-free-trial.svg';
import { ReactComponent as WarningIcon } from '#assets/warning.svg';

import { BANNER_STATUS } from './constants';

export const getIcon = status =>
  ({
    [BANNER_STATUS.INFO]: InfoIcon,
    [BANNER_STATUS.ALERT]: AlertIcon,
    [BANNER_STATUS.WARNING]: WarningIcon
  }[status]);

export const isStatus = statusToCompare => status => status === statusToCompare;
export const isWarning = isStatus(BANNER_STATUS.WARNING);
export const isAlert = isStatus(BANNER_STATUS.ALERT);
export const isInfo = isStatus(BANNER_STATUS.INFO);
