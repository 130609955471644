import i18next from 'i18next';

import { MODAL_MODE } from '#constants/modal';

import { FIELDS } from './TaskModal/constants';

i18next.addResources('es', 'Task', {
  searchBar: 'Buscar por título',
  assigned: 'Solo mis asignados',
  newTask: 'Nueva tarea',
  startDate: 'Inicio de tarea',
  endDate: 'Fin de tarea',
  legalCases: 'Expedientes',
  assignees: 'Personas asignadas',
  emptyAssignes: 'No hay personas asignadas',
  emptyLegalCases: 'No hay expedientes',
  searchAssignees: 'Buscar asignado',
  filterAssignees: 'Por personas asignadas'
});

i18next.addResources('es', 'TaskType', {
  groupName: 'Tipo de tarea',
  audience: 'Audiencia',
  reminder: 'Recordatorio',
  expiration: 'Vencimiento',
  other: 'Otros',
  undefined: 'Sin definir'
});

i18next.addResources('es', 'TaskStatus', {
  groupName: 'Estados',
  finished: 'Terminada',
  inProgress: 'En proceso',
  pending: 'Pendiente'
});

i18next.addResources('es', 'TaskHeaders', {
  task: '{{count}} tarea',
  task_plural: '{{count}} tareas',
  taskType: 'Tipo de tarea',
  assignees: 'Asignado/s',
  startDate: 'Inicio',
  endDate: 'Fin'
});

i18next.addResources('es', 'TaskModal', {
  titleRequired: 'Debe ingresar un título',
  titlePlaceholder: 'Título de tarea',
  descriptionPlaceholder: 'Descripción de tarea',
  linkLabel: 'Link a la audiencia',
  details: 'Detalles',
  actionPlaceholder: 'Seleccione una actuación',
  files: 'Adjuntos',
  uploadFile: 'Adjuntar archivo',
  document: 'Documento',
  image: 'Imagen',
  uploadError: 'Error de carga',
  fileSizeError: 'Mayor a 25MB',
  maxSize: 'Tamaño máx. 25MB',
  errorDescription:
    'Ocurrió un error al cargar algunos de sus archivos. Puede reintentar haciendo click en “Guardar”, o bien cancelar la carga de estos archivos y guardar nuevamente.',
  invalidEndDate: 'Fin de tarea anterior al inicio'
});

const { LEGAL_CASE, LEGAL_ACTION } = FIELDS;
const { CREATE, EDIT } = MODAL_MODE;

i18next.addResources('es', 'TaskCase', {
  [`${LEGAL_CASE}label`]: 'Expediente',
  [`${LEGAL_CASE}placeholder`]: 'Seleccione un expediente',
  [`${LEGAL_CASE}searchPlaceholder`]: 'N° de expediente, carátula',
  [`${LEGAL_CASE}${CREATE}tooltip`]:
    'Se encuentra dentro del detalle del expediente. Solo puede crear una tarea para el expediente elegido.',
  [`${LEGAL_CASE}${EDIT}tooltip`]: 'No puede editar el expediente. Para utilizar otro, cree una nueva tarea.',
  legalCaseRequired: 'Debe ingresar un expediente',
  [`${LEGAL_CASE}noResults`]: 'No se encontraron expedientes',
  [`${LEGAL_ACTION}label`]: 'Actuación',
  [`${LEGAL_ACTION}placeholder`]: 'Seleccione una actuación',
  [`${LEGAL_ACTION}searchPlaceholder`]: 'Fecha de carga a PJN, descripción',
  [`${LEGAL_ACTION}${CREATE}tooltip`]: 'Para poder elegir una actuación, seleccione un expediente.',
  [`${LEGAL_ACTION}noResults`]: 'No se encontraron actuaciones'
});
