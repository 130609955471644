import { createTypes, completeTypes } from 'redux-recompose';

import * as SettingsService from '../../services/SettingsService';

import { TARGETS } from './constants';

export const actions = createTypes(
  completeTypes(['GET_SETTINGS', 'GET_SCRAPER_HEALTH'], ['SET_SERVER_ERROR']),
  '@@APP_SETTINGS'
);

export const actionCreators = {
  getSettings: () => ({
    type: actions.GET_SETTINGS,
    target: TARGETS.SETTINGS,
    service: SettingsService.getSettings
  }),
  getScraperHealth: () => ({
    type: actions.GET_SCRAPER_HEALTH,
    target: TARGETS.SCRAPER_HEALTH,
    service: SettingsService.getScraperHealth
  }),

  setServerError: data => ({
    type: actions.SET_SERVER_ERROR,
    target: TARGETS.SERVER_ERROR,
    payload: data
  })
};

export default actionCreators;
