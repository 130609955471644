export const TARGETS = {
  CREATE_WORKSPACE: 'createWorkspace',
  CURRENT_WORKSPACE: 'currentWorkspace',
  GET_WORKSPACE: 'getWorkspace',
  ACCEPT_INVITATION: 'acceptInvitation',
  CURRENT_INVITE_ID: 'currentInviteId',
  DELETE_INVITATION: 'deleteInvitation',
  WORKSPACE_CREDENTIALS: 'workspaceCredentials',
  WORKSPACE_MEMBERS: 'workspaceMembers',
  INVITE_TO_WORKSPACE: 'inviteToWorkspace',
  MEMBERS_OF_WORKSPACE: 'membersOfWorkspace',
  CREDENTIALS_OF_WORKSPACE: 'credentialsOfWorkspace'
};
