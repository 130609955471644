import i18next from 'i18next';

import { ROUTES } from '#constants/routes';
import workspaceIcon from '#assets/workspace.svg';

import WorkspaceTitle from './components/WorkspaceTitle';

export const getWorkspaceItem = currentWorkspace => ({
  name: i18next.t('MenuItems:workspace'),
  icon: () => <img src={currentWorkspace?.picture || workspaceIcon} className="w-10 max-w-none rounded-lg" />,
  route: {
    path: ROUTES.WORKSPACE.path
  },
  titleComponent: WorkspaceTitle
});
