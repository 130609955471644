import { lazy } from 'react';

import { ALL_ROLE_VALUES, ROLES } from './users';

const Home = lazy(() => import('#screens/Home'));
const Login = lazy(() => import('#screens/Login'));
const LoginRedirect = lazy(() => import('#screens/LoginRedirect'));
const LawyerOnboarding = lazy(() => import('#screens/LawyerOnboarding'));
const File = lazy(() => import('#screens/File'));
const Notifications = lazy(() => import('#screens/Notifications'));
const Schedule = lazy(() => import('#screens/Schedule'));
const Profile = lazy(() => import('#screens/Profile'));
const PDF = lazy(() => import('#screens/PDF'));
const SubscriptionPlans = lazy(() => import('#screens/SubscriptionPlans'));
const Credentials = lazy(() => import('#screens/Credentials'));
const Checkout = lazy(() => import('#screens/Checkout'));
const SubscriptionCancellation = lazy(() => import('#screens/SubscriptionCancellation'));
const ErrorScreen = lazy(() => import('#screens/ErrorScreen'));
const WorkspaceCreationRedirect = lazy(() => import('#screens/WorkspaceCreationRedirect'));
const WorkspaceInvitationRedirect = lazy(() => import('#screens/WorkspaceInvitationRedirect'));
const Workspace = lazy(() => import('#screens/Workspace'));
const WorkspaceConfiguration = lazy(() => import('#screens/WorkspaceConfiguration'));
const ElectronicCards = lazy(() => import('#screens/ElectronicCards'));

export const ROUTES = {
  HOME: {
    path: '/files',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    component: Home,
    withOverflow: true
  },
  PDF: {
    path: '/pdf/:pdfName',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: PDF,
    withOverflow: true,
    noMenu: true,
    noTopBanner: true
  },
  CHECKOUT: {
    path: '/checkout',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.OWNER],
    publicRoute: false,
    component: Checkout,
    withOverflow: true,
    noMenu: true,
    noTopBanner: true
  },
  SUBSCRIPTION_PLANS: {
    path: '/subscription/plans',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.OWNER],
    publicRoute: false,
    component: SubscriptionPlans,
    withOverflow: true,
    noMenu: true,
    noTopBanner: true
  },
  LOGIN_REDIRECT: {
    path: '/login_redirect',
    basePath: '',
    publicRoute: true,
    supportedRoles: ALL_ROLE_VALUES,
    exact: false,
    component: LoginRedirect,
    noMenu: true,
    noTopBanner: true
  },
  LOGIN: {
    path: '/login',
    basePath: '',
    publicRoute: true,
    supportedRoles: ALL_ROLE_VALUES,
    exact: false,
    component: Login,
    noMenu: true,
    noTopBanner: true
  },
  LAWYER_ONBOARDING: {
    path: '/onboarding/lawyer',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: LawyerOnboarding,
    needsOnboarding: false,
    noMenu: true,
    noTopBanner: true
  },
  FILE_DETAILS: {
    path: '/files/:id',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: File,
    withOverflow: true,
    availableOnFreeTrial: false
  },
  NOTIFICATIONS: {
    path: '/notifications',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: Notifications,
    withOverflow: true
  },
  SCHEDULE: {
    path: '/schedule',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: Schedule,
    withOverflow: true
  },
  PROFILE: {
    path: '/profile',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: Profile,
    withOverflow: true
  },
  CREDENTIALS: {
    path: '/credentials',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    publicRoute: false,
    component: Credentials,
    withOverflow: true
  },
  SUBSCRIPTION_CANCELLATION: {
    path: '/subscription/cancel',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.OWNER],
    publicRoute: false,
    component: SubscriptionCancellation,
    withOverflow: true,
    noMenu: true,
    noTopBanner: true
  },
  WORKSPACE_CREATION_REDIRECT: {
    path: '/workspace_creation_redirect',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.OWNER, ROLES.LAWYER],
    publicRoute: false,
    component: WorkspaceCreationRedirect,
    noMenu: true,
    noTopBanner: true
  },
  ERROR: {
    path: '/error',
    basePath: '',
    exact: true,
    publicRoute: true,
    privateRoute: true,
    component: ErrorScreen,
    withOverflow: true,
    noMenu: true,
    noTopBanner: true
  },
  WORKSPACE_INVITATION_REDIRECT: {
    path: '/workspace_invitation_redirect',
    basePath: '',
    publicRoute: true,
    supportedRoles: ALL_ROLE_VALUES,
    exact: false,
    component: WorkspaceInvitationRedirect,
    noMenu: true,
    noTopBanner: true,
    noRedirect: true
  },
  WORKSPACE: {
    path: '/workspace',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    component: Workspace,
    withOverflow: true
  },
  WORKSPACE_CONFIGURATION: {
    path: '/workspace/configuration',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.OWNER, ROLES.ADMIN],
    component: WorkspaceConfiguration,
    withOverflow: true
  },
  ELECTRONIC_CARDS: {
    path: '/electronic_cards',
    basePath: '',
    exact: true,
    supportedRoles: ALL_ROLE_VALUES,
    component: ElectronicCards,
    withOverflow: true
  }
};

export const DEFAULT_ROUTE_PATH = {
  [ROLES.LAWYER]: ROUTES.HOME.path,
  [ROLES.ADMIN]: ROUTES.HOME.path,
  [ROLES.OWNER]: ROUTES.HOME.path
};
