import i18next from 'i18next';

i18next.addResources('es', 'Calendar', {
  title: 'Agenda',
  next: 'Siguiente',
  previous: 'Anterior',
  today: 'Hoy',
  create: 'Crear',
  month: 'Mes',
  showMore: '+{{count}} más',
  week: 'Semana',
  day: 'Día',
  createTask: 'Crear tarea',
  downloadSchedule: 'Descargar agenda',
  export: 'URL para Outlook',
  copySuccessTitle: 'Agenda exportada.',
  copySuccessSubtitle: 'URL copiada en el portapapeles.'
});
